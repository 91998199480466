import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface LogRocketSlice {
  isLogRocketInit: boolean;
}

const initialState: LogRocketSlice = {
  isLogRocketInit: false,
};

export const logRocketSlice = createSlice({
  name: "logRocket",
  initialState,
  reducers: {
    setIsLogRocketInit: (state, action: PayloadAction<LogRocketSlice>) => {
      state.isLogRocketInit = action.payload.isLogRocketInit;
    },
  },
});

export const { setIsLogRocketInit } = logRocketSlice.actions;

export default logRocketSlice.reducer;
