import { RegionBoundingBox } from "../../types/buildRegions";

export const initialMapZoom = 7.5;

export function getZoomLevel(
  bounds: RegionBoundingBox,
  mapWidth: number,
  mapHeight: number
) {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  function latRad(lat: number) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx: number, worldPx: number, fraction: number) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  const latFraction = (latRad(bounds.maxLat) - latRad(bounds.minLat)) / Math.PI;

  const lngDiff = bounds.maxLng - bounds.minLng;
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(mapHeight, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapWidth, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

/**
 * Formats a GeoJSON compatible coordinates object and transforms it into a
 * Google Maps compatible object.
 *
 * @param coordinates - geojson polygon coordinates array
 * @returns google maps `paths` compatible object
 */
export function formatBuildRegionsData(data: any) {
  const jsonObject = data;

  return jsonObject.map((coord: any) =>
    coord.map((c: any) => ({ lat: c[1], lng: c[0] }))
  );
}

/**
 * Composes a GeoJSON coordinates object into a PostGIS compatible
 * FeatureColection object for compatibility with upserts.
 *
 * @param geometry - geojson polygon coordinates array
 * @returns postgis `FeatureCollection` compatible object
 */
export function composeBuildRegionUpdateInput(geometry: any) {
  const input = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry,
      },
    ],
  };
  return input;
}

