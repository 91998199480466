import { getAuth } from "firebase/auth";

import { GET_ACCEPTED_USER_TERMS_OF_SERVICE } from "../api/v1/graphqlQueries";
import { BANYAN_BASE_URL } from "../api/v1/banyanV1API";

const REACT_APP_BANYAN_GRAPHQL_ENDPOINT = `${BANYAN_BASE_URL}graphql`;

export async function mustAcceptNewTermsOfService(): Promise<boolean> {
  const user = getAuth()?.currentUser;

  if (!user) {
    return false;
  }

  const idToken = await user.getIdToken();

  const res = await fetch(REACT_APP_BANYAN_GRAPHQL_ENDPOINT, {
    method: "POST",
    body: JSON.stringify({
      query: GET_ACCEPTED_USER_TERMS_OF_SERVICE,
      variables: { input: { userId: user.uid } },
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  });

  const { data, errors } = await res.json();

  if (errors?.length) {
    console.error(errors?.[0]?.message);
  }

  return data.acceptedCurrentTermsOfService.length === 0;
}
