import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface PhaseSlice {
  selectedPhases: String[];
}

const initialState: PhaseSlice = {
  selectedPhases: [],
};

export const phaseSlice = createSlice({
  name: "phase",
  initialState,
  reducers: {
    selectPhase: (state, action: PayloadAction<{ phases: String[] }>) => {
      state.selectedPhases = [
        ...state.selectedPhases,
        ...action.payload.phases,
      ];
    },
    removePhase: (state, action: PayloadAction<{ phases: String[] }>) => {
      state.selectedPhases = state.selectedPhases.filter(
        (phase) => !action.payload.phases.includes(phase)
      );
    },
    reset: (state) => {
      state.selectedPhases = [];
    },
  },
});

export const { selectPhase, removePhase, reset } = phaseSlice.actions;

export default phaseSlice.reducer;
