import { Alert, AlertColor, AlertTitle, Collapse } from "@mui/material";
import { useEffect, useState } from "react";

interface CollapsableAlertProps {
  open?: boolean;
  title: string;
  text: string;
  severity: AlertColor;
  secondsOpen?: number;
  onClose: () => void;
}

export const CollapsableAlert = ({
  open,
  title,
  text,
  severity,
  secondsOpen,
  onClose,
}: CollapsableAlertProps) => {
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    setCollapsed(!open);
    if (open && secondsOpen != null) {
      setTimeout(() => {
        onClose();
      }, secondsOpen * 1000);
    }
  }, [open, secondsOpen, onClose]);

  return (
    <Collapse in={!collapsed} style={{ position: "absolute", width: "100%" }}>
      <Alert severity={severity} onClose={onClose}>
        <AlertTitle>{title}</AlertTitle>
        {text}
      </Alert>
    </Collapse>
  );
};
