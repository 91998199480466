import { pangaea } from "../api/v1/pangaea";

export const {
  useLazyGetEmailAccessAuthQuery,
  useGeneratePhaseFormLinkMutation,
  useLazyGetProjectCardQuery,
  useGetAllProjectsQuery,
  useGetBuildRegionsByIdQuery,
  useCreateBuildRegionMutation,
  useUpdateBuildRegionMutation,
  useGetContractorByEmailQuery,
  useUpdateContractorMutation,
} = pangaea;
