import { Box, Typography } from "@mui/material";
import React from "react";

export const MarketplaceBanner = () => {
  return (
    <Box
      sx={{
        p: "20px",
        margin: "20px",
        backgroundColor: "#FFFDCC",
      }}
    >
      <Typography variant="body1">
        Welcome to your contractor dashboard, your one-stop-shop for all your
        Yardzen leads and projects.
        <br />
        <br />
        You will still receive emails and text messages alerting you of new
        Yardzen leads, however, these new leads are expecting communication from
        you on Yardzen&#39;s new messaging platform:{" "}
        <a href="https://pros.yardzen.com/" type="em" className="font-bold">
          https://pros.yardzen.com/
        </a>
        . These new leads/projects will have a “Marketplace” flag so you can
        easily identify the ones using Yardzen&#39;s messaging platform. You
        will also receive emails and text messages alerting you of any unread
        client messages.
        <br />
        <br />
        Timely communication and updates are crucial in ensuring the best
        Yardzen client experience, so please continue to update the status for
        each project within 24 hours of each milestone.
        <br />
        <br />
        We&#39;ll continue to evolve this dashboard so that it adds value and
        streamlines your operating processes! As always, if you have feedback,
        please reach out to us via text 415-944-9354 or email{" "}
        <a
          href="mailto:pro-network@yardzen.com"
          type="em"
          className="font-bold"
        >
          pro-network@yardzen.com
        </a>
        .
      </Typography>
    </Box>
  );
};
