/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { css } from "@emotion/react/macro";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { logout } from "../util/logout";

const styles = {
  loggedInEmail: css({
    marginLeft: "auto",
  }),
};
type Props = {
  loggedInEmail: string | null | undefined;
  isAdmin?: boolean | null | undefined;
};

const TopNav: FC<Props> = ({ loggedInEmail, isAdmin }: Props) => {
  const navigate = useNavigate();

  return (
    <AppBar component="nav" position="sticky">
      <Toolbar>
        <Typography variant="h6" component="div">
          Yardzen Contractor Dashboard
        </Typography>

        {loggedInEmail ? (
          <>
            <Typography
              variant="subtitle1"
              component="div"
              css={styles.loggedInEmail}
            >
              {loggedInEmail}
            </Typography>
            {isAdmin && (
              <Button
                style={{ color: "white" }}
                onClick={() => {
                  logout();
                  navigate("/login");
                }}
              >
                Logout
              </Button>
            )}
          </>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

export { TopNav };
