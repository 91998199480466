/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react/macro";
import { Container } from "@mui/material";
import { FC } from "react";
import { Grid } from "react-loader-spinner";

const styles = {
  loadingContainer: css({
    width: "100%",
    display: "flex",
    justifyContent: "center",
  }),
};

export const LoadingSpinner: FC = () => {
  return (
    <Container css={styles.loadingContainer}>
      <Grid
        height="80"
        width="80"
        color="#1976d2"
        ariaLabel="grid-loading"
        radius="12.5"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </Container>
  );
};
