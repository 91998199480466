import React from "react";
import { Provider } from "react-redux";
import { store } from "./store";
import { AppRouter } from "./AppRouter";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { AuthProvider } from "./contexts/AuthContext";
import { SplitProviderWrapper } from "./util/Split/SplitProviderWrapper";

export const App: React.FC = () => {
  return (
    <Provider store={store}>
      <AuthProvider>
        <BrowserRouter>
          <CssBaseline>
            <SplitProviderWrapper>
              <AppRouter />
            </SplitProviderWrapper>
          </CssBaseline>
        </BrowserRouter>
      </AuthProvider>
    </Provider>
  );
};
