/** @jsxImportSource @emotion/react */
import { FC, useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
} from "@mui/material";
import { css } from "@emotion/react";

import { CardLabel } from "./CardLabel";
import { Project } from "../slices/projectSlice";
import moment from "moment";
import { isDateOverdue } from "../util/isDateOverdue";
import { CardSubheader } from "./CardSubheader";
import { StyleHelpers } from "../constants/styleConstants";
import { useSegment } from "../util/Segment/useSegment";
import { SegmentClickTypes, SegmentFlows } from "../util/Segment/constants";
import { useGeneratePhaseFormLinkMutation } from "../services/pangaeaService";
import { noUpdateNeededBuildMarketplacePhases } from "../constants/phaseGroups";

interface Props {
  jobDetails: Project;
}

const styles = {
  labelContainer: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [StyleHelpers.mediaQueries.xsScreen]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  }),
};

const JobCard: FC<Props> = ({ jobDetails }) => {
  const [phaseFormUrl, setPhaseFormUrl] = useState<string | null>(null);
  const segment = useSegment();
  const parentPipe = jobDetails?.parent_pipe;
  const client = jobDetails?.child_relations?.find(
    (item: any) =>
      item &&
      item.cards.length >= 1 &&
      item.cards[0].fields.find((item: any) => item.name === "Full Name")
  )?.cards?.[0];
  const [generatePhaseFormLink] = useGeneratePhaseFormLinkMutation();

  const address = client?.fields.find((item: any) => item.name === "Address");
  const city = client?.fields.find((item: any) => item.name === "City");
  const state = client?.fields.find((item: any) => item.name === "State");
  const formattedAddress = `${address?.value}, ${city?.value}, ${state?.value}`;
  const currentStage = jobDetails?.current_phase?.name;
  const noUpdateNeeded =
    noUpdateNeededBuildMarketplacePhases.includes(currentStage);

  const dueDate = jobDetails?.due_date
    ? moment(jobDetails.due_date).format("MM/DD/YYYY")
    : "N/A";

  const mediaLink = client?.fields.find(
    (item: any) => item.name === "Link to shared media"
  );

  useEffect(() => {
    const fetchUrl = async () => {
      try {
        const {
          data: { url },
        }: any = await generatePhaseFormLink({
          projectId: jobDetails.id,
          pipeName: parentPipe === "Marketplace" ? "marketplace" : "project",
        });
        setPhaseFormUrl(url);
      } catch (error) {
        console.error(error);
      }
    };

    if (noUpdateNeeded) {
      return;
    }
    fetchUrl();
  }, [jobDetails.id, parentPipe, generatePhaseFormLink, noUpdateNeeded]);

  const handleMediaClick = () => {
    if (mediaLink) {
      const win = window.open(mediaLink.value, "_blank");
      win?.focus();
    }
  };

  return (
    <Card
      sx={{
        [StyleHelpers.mediaQueries.xsScreen]: {
          padding: "5px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "14px",
        }}
      >
        <CardHeader
          style={{ padding: "5px", marginLeft: "5px" }}
          title={formattedAddress}
          subheader={
            <CardSubheader
              name={jobDetails.title}
              mediaLink={mediaLink}
              handleMediaClick={handleMediaClick}
            />
          }
          subheaderTypographyProps={{
            color: "black",
          }}
        />
        <Box
          sx={{
            p: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>Matching:</Box>
          <Box>{parentPipe}</Box>
        </Box>
      </Box>
      <CardContent style={{ padding: "5px" }}>
        <div css={styles.labelContainer}>
          <CardLabel label="Current Stage:" value={currentStage} />
          {!noUpdateNeeded && (
            <CardLabel
              label="Next Update Needed:"
              value={dueDate}
              isOverdue={isDateOverdue(new Date(dueDate))}
            />
          )}
          {noUpdateNeeded && <Box>No update needed</Box>}
          {!phaseFormUrl && !noUpdateNeeded && <CircularProgress />}
          {phaseFormUrl && !noUpdateNeeded && (
            <CardActions
              sx={{
                [StyleHelpers.mediaQueries.xsScreen]: {
                  padding: "0",
                },
              }}
            >
              <Button
                target="_blank"
                href={phaseFormUrl}
                onClick={() => {
                  segment.trackClicked({
                    button_name: "Provide Update",
                    click_type: SegmentClickTypes.BUTTON,
                    button_content: "Provide Update",
                    flow_name: SegmentFlows.CONTRACTOR_PAGE,
                  });
                }}
              >
                Provide Update
              </Button>
            </CardActions>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export { JobCard };
