/** @jsxImportSource @emotion/react */

import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { logout } from "../util/logout";

export const ForceLogout = () => {
  useEffect(() => {
    logout();
  }, []);

  return <Navigate to="/" />;
};
