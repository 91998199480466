import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import {
  CONTACTOR_LOGIN_STORAGE,
  ContractorLoginStorage,
} from "../services/auth";

interface AuthContextInterface {
  currentUser: User | null;
  loading: boolean;
}

export const AuthContext = createContext<AuthContextInterface>({
  currentUser: null,
  loading: true,
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<any> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const contractorLogin = useLocalStorage<ContractorLoginStorage>(
    CONTACTOR_LOGIN_STORAGE
  )[0];
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
  }, [contractorLogin]);

  return (
    <AuthContext.Provider value={{ currentUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
