const StyleVariables = {
  breakpoints: {
    small: 900,
    xs: 600,
  },
};

const StyleHelpers = {
  mediaQueries: {
    smallScreen: `@media(max-width: ${StyleVariables.breakpoints.small}px)`,
    xsScreen: `@media(max-width: ${StyleVariables.breakpoints.xs}px)`,
  },
};

export { StyleVariables, StyleHelpers };
