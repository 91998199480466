import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface UpdateSlice {
  updateDue: boolean;
  noUpdateDue: boolean;
}

const initialState: UpdateSlice = {
  updateDue: true,
  noUpdateDue: false,
};

export const updateSlice = createSlice({
  name: "update",
  initialState,
  reducers: {
    setUpdateDue: (state, action: PayloadAction<{ updateDue: boolean }>) => {
      state.updateDue = action.payload.updateDue;
    },
    setNoUpdateDue: (
      state,
      action: PayloadAction<{ noUpdateDue: boolean }>,
    ) => {
      state.noUpdateDue = action.payload.noUpdateDue;
    },
  },
});

export const { setNoUpdateDue, setUpdateDue } = updateSlice.actions;

export default updateSlice.reducer;
