import { useState, useEffect, useRef, useCallback } from "react";

interface UseLocalStorage {
  <t extends {}>(key: string): [t | null, (val: t | null) => void];
}

export const useLocalStorage: UseLocalStorage = <t>(key: string) => {
  const getVal = useCallback(() => {
    const item = localStorage.getItem(key);

    if (!item) {
      return null;
    }

    return JSON.parse(item) as t;
  }, [key]);

  const [value, setValue] = useState<t | null>(getVal);
  const firstRunComplete = useRef<boolean>(false);

  useEffect(() => {
    if (!firstRunComplete.current) {
      firstRunComplete.current = true;
      return;
    }

    return setValue(getVal());
  }, [key, getVal]);

  const updateValue = (val: t | null): void => {
    setValue(val);

    localStorage.setItem(
      key,
      typeof val === "string" ? val : JSON.stringify(val)
    );
  };

  return [value, updateValue];
};
