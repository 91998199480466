/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { css } from "@emotion/react";

import { JobCard } from "./JobCard";
import { Project } from "../slices/projectSlice";

interface Props {
  jobs: Project[];
}

const styles = {
  jobListContainer: css({
    display: "flex",
    flexDirection: "column",
  }),
  jobWrapper: css({
    margin: "10px 0",
  }),
};

const JobList: FC<Props> = ({ jobs }) => {
  return (
    <div css={styles.jobListContainer}>
      {jobs.map((job, index) => (
        <div css={styles.jobWrapper} key={index}>
          <JobCard jobDetails={job} />
        </div>
      ))}
    </div>
  );
};

export { JobList };
