/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { css } from "@emotion/react/macro";

import Container from "@mui/material/Container";

import { Typography } from "@mui/material";
import { JobListContainer } from "./JobListContainer";
import { useAppSelector } from "../hooks";
import { StyleHelpers } from "../constants/styleConstants";
import { MarketplaceBanner } from "./MarketplaceBanner";

const styles = {
  jobListContainer: css({
    display: "flex",
    flexDirection: "column",
    paddingTop: "30px",
  }),
};

const ProjectListPage: FC = () => {
  const { title } = useAppSelector((state) => state.contractor);
  return (
    <>
      <Typography
        variant="h2"
        component="h1"
        sx={{
          [StyleHelpers.mediaQueries.xsScreen]: {
            fontSize: "30px",
          },
        }}
      >
        {title}
      </Typography>
      <MarketplaceBanner />

      <Container css={styles.jobListContainer}>
        <JobListContainer />
      </Container>
    </>
  );
};

export { ProjectListPage };
