export const ALL_PHASES = "AllPhases";
export const LOST_PHASES = "lostPhases";
export const COMPLETE_PHASES = "completePhases";
export const IN_PROGRESS_PHASES = "inProgressPhases";

// We currently only want to show jobs with these phases in JobList.tsx
export const baseInProgressPhases = [
  "Introduction Made",
  "Site Walk Date Unknown (Stand By)",
  "Site Walk Scheduled",
  "Site Walk Delayed (Stand By)",
  "Creating Estimate",
  "Estimate Delivery Delayed (stand by)",
  "Estimate Delivered",
  "Estimate in review (stand by)",
  "Contract Signed",
  "Work not started (Standy By)",
  "Work In Progress",
  "Project Delay (Stand By)",
  "Work Completed",
  "Invoice Sent",
];

export const buildMarketplacePhases = [
  "Client Matched",
  "Conversation Started",
  "Site Walk Scheduled",
  "Site Walk Complete",
  "Waiting On Estimate From Pro",
  "Processing Estimate",
  "Estimate Submitted to Client",
  "Contract Signed/Client Ready to Make Payment",
  "Client Ready for Invoice",
  "Initial Invoice/Deposit Sent",
  "Initial Payment/Deposit Paid",
  "Waiting for Project to Start",
  "Work Completed",
  "Work in Progress",
];

export const noUpdateNeededBuildMarketplacePhases = [
  "Site Walk Complete",
  "Processing Estimate",
  "Estimate Submitted to Client",
  "Client Ready for Invoice",
  "Initial Invoice/Deposit Sent",
];

export const phaseGroups = {
  [LOST_PHASES]: ["Lost", "Declined Projects", "Project Not Moving Forward"],

  [COMPLETE_PHASES]: ["Invoice Paid"],

  [IN_PROGRESS_PHASES]: [
    "Revision Shipped",
    "Ready For Pro Match",
    "Projects for Prospect",
    "Sent Project For Review",
    ...baseInProgressPhases,
    "Phased Projects ",
  ],
};

export const phaseFiltersToDisplay: string[] = [ALL_PHASES];
