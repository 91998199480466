/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react/macro";
import Container from "@mui/material/Container";
import { ProjectListPage } from "../components/ProjectListPage";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { processProjectDataFromCard } from "../services/projects";
import { useAppSelector } from "../hooks";
import { setLoading } from "../slices/loadingSlice";
import { setError } from "../slices/errorSlice";
import { useLogRocketIdentify } from "../hooks/useLogRocketIdentify";
import { useLocalStorage } from "../hooks/useLocalStorage";
import {
  CONTACTOR_LOGIN_STORAGE,
  ContractorLoginStorage,
} from "../services/auth";
import { useSegment } from "../util/Segment/useSegment";
import { useLazyGetProjectCardQuery } from "../services/pangaeaService";
import { setContractor } from "../slices/contractorSlice";
import { setProjects } from "../slices/projectSlice";
import { isYardzenEmail } from "../util/isYardzenEmail";
import { AuthContext } from "../contexts/AuthContext";
import { LoadingSpinner } from "../components/LoadingSpinner";

const styles = {
  pageContainer: css({
    display: "flex",
    flexDirection: "column",
  }),
  mainContent: css({
    paddingTop: "30px",
  }),
};

export const ContractorPage: React.FC = () => {
  const contractorLogin = useLocalStorage<ContractorLoginStorage>(
    CONTACTOR_LOGIN_STORAGE
  )[0];
  const { loading } = useAppSelector(
    (state: { loading: any }) => state.loading
  );
  const { errorStatus, errorMessage } = useAppSelector(
    (state: { error: any }) => state.error
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const segment = useSegment();
  const { currentUser } = useContext(AuthContext);

  const [
    fetchGetProjectCard,
    { data, error: errorProjectCardProfile, isFetching: isProjectCardFetching },
  ] = useLazyGetProjectCardQuery();
  const { contractor, projects } = processProjectDataFromCard(data);
  useLogRocketIdentify({ userId: currentUser?.email || "" });

  useEffect(() => {
    if (errorProjectCardProfile)
      dispatch(
        setError({
          errorMessage: (errorProjectCardProfile as Error)?.message,
          errorStatus: true,
        })
      );
  }, [errorProjectCardProfile, dispatch]);

  useEffect(() => {
    dispatch(setLoading({ loading: isProjectCardFetching }));
  }, [isProjectCardFetching, dispatch]);

  useEffect(() => {
    if (contractor) dispatch(setContractor(contractor));
  }, [contractor, dispatch]);

  useEffect(() => {
    if (projects) {
      dispatch(setProjects({ projects }));
      dispatch(
        setError({
          errorMessage: "",
          errorStatus: false,
        })
      );
    }
  }, [projects, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (contractorLogin?.contractorId !== id) {
          if (isYardzenEmail(currentUser?.email || "")) {
            // Redirect Yardzen employees to authorize page to reset the contractor id
            return navigate(`/authorize?contractorId=${id}`);
          } else {
            // Redirect to authorized contractor id if not a Yardzen employee
            return navigate(`/${contractorLogin?.contractorId}`);
          }
        }

        await fetchGetProjectCard({ contractorId: id });

        if (contractorLogin?.email) {
          segment.identify(contractorLogin.email, {
            conractorId: contractorLogin?.contractorId,
          });
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log("error", error);
      }
    };

    fetchData();
  }, [
    id,
    navigate,
    fetchGetProjectCard,
    contractorLogin,
    segment,
    currentUser?.email,
  ]);

  const renderBody = () => {
    if (loading) {
      return <LoadingSpinner />;
    }

    if (errorStatus) {
      return (
        <>
          <h1>
            We're sorry, something went wrong. Please contact us at{" "}
            <a
              href="mailto:support@yardzen.com"
              type="em"
              className="font-bold"
            >
              support@yardzen.com
            </a>{" "}
            for assistance
          </h1>
          <p>{errorMessage}</p>
        </>
      );
    }

    return (
      <Container>
        <ProjectListPage />
      </Container>
    );
  };

  return <Container css={styles.pageContainer}>{renderBody()}</Container>;
};
