import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import {
  CONTACTOR_LOGIN_STORAGE,
  ContractorLoginStorage,
  validateAuth,
} from "../services/auth";

export const Authorize = () => {
  const searchParams = useSearchParams()[0];
  const navigate = useNavigate();
  const setContractorLogin = useLocalStorage<ContractorLoginStorage>(
    CONTACTOR_LOGIN_STORAGE
  )[1];

  useEffect(() => {
    const contractorId = searchParams.get("contractorId");
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        setContractorLogin({
          contractorId: contractorId,
          email: user.email,
        });
        navigate(`/${contractorId}`);
      } else {
        navigate(`/login`);
      }
    });
    validateAuth();
  });

  return null;
};
