import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuth } from "firebase/auth";
import { processAuthResponse } from "../../services/auth";
import {
  BuildRegion,
  BuildRegionsByIdPathParams,
  BuildRegionsUpdatePathParams,
  ContractorDto,
  CreateBuildRegionsDto,
  UpdateBuildRegionsDto,
  UpdateContractorDto,
  UpdateContractorPathParams,
} from "./pangaeaTypes";
export const PANGAEA_V1_BASE_URL = `${process.env.REACT_APP_PANGAEA_BASE_URL}`;

enum ApiTags {
  PROJECT_DASHBOARD = "PROJECT_DASHBOARD",
  PROJECT_FORM_LINK = "PROJECT_FORM_LINK",
  ADMIN_PROJECTS = "ADMIN_PROJECTS",
  BUILD_REGIONS = "BUILD_REGIONS",
  CONTRACTORS = "CONTRACTORS",
}

export const pangaea = createApi({
  reducerPath: "pangaeaAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: PANGAEA_V1_BASE_URL,
    prepareHeaders: async (headers) => {
      const user = getAuth()?.currentUser;
      if (user) {
        headers.set("Authorization", `Bearer ${await user.getIdToken()}`);
      }
      return headers;
    },
  }),

  tagTypes: Object.values(ApiTags),
  endpoints: (builder) => ({
    getEmailAccessAuth: builder.query<any, { email: string }>({
      query: ({ email }) => {
        const path = "contractor-portal/authorize";
        if (!email) throw new Error("email required for getEmailAccessAuth");

        return {
          url: `${path}?email=${email}`,
          method: "GET",
        };
      },
      transformResponse: (response: string, _meta, { email }) =>
        processAuthResponse({ email, response }),
    }),
    getProjectCard: builder.query<any, { contractorId: string | undefined }>({
      query: ({ contractorId }) => {
        const url = `contractor-portal/contractors/${contractorId}/projects`;
        if (!contractorId)
          throw new Error("contractorId required for getProjectCard");

        return {
          url,
          method: "GET",
        };
      },
      providesTags: [ApiTags.PROJECT_DASHBOARD],
    }),
    generatePhaseFormLink: builder.mutation<
      any,
      { projectId: string; pipeName: "project" | "marketplace" }
    >({
      query: (body) => ({
        url: "contractor-portal/projects",
        method: "POST",
        body,
      }),
      invalidatesTags: [ApiTags.PROJECT_FORM_LINK],
    }),
    getAllProjects: builder.query<any, {}>({
      query: () => {
        const url = "/contractor-portal/contractors";

        return {
          url,
          method: "GET",
        };
      },
      providesTags: [ApiTags.PROJECT_DASHBOARD],
    }),
    getContractorByEmail: builder.query<ContractorDto, string>({
      query: (email) => ({
        url: `contractor-portal/${email}/email`,
        method: "GET",
      }),
      providesTags: [ApiTags.CONTRACTORS],
    }),
    updateContractor: builder.mutation<
      boolean,
      UpdateContractorDto & UpdateContractorPathParams
    >({
      query: ({ id, ...body }) => ({
        url: `contractor-portal/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [ApiTags.CONTRACTORS],
    }),

    getBuildRegionsById: builder.query<BuildRegion, BuildRegionsByIdPathParams>(
      {
        query: ({ id }) => ({
          url: `contractor-portal/${id}/get-region`,
          method: "GET",
        }),
        providesTags: [ApiTags.BUILD_REGIONS],
      }
    ),
    createBuildRegion: builder.mutation<BuildRegion, CreateBuildRegionsDto>({
      query: (body) => ({
        url: `contractor-portal/create-region`,
        method: "POST",
        body,
      }),
    }),
    updateBuildRegion: builder.mutation<
      boolean,
      UpdateBuildRegionsDto & BuildRegionsUpdatePathParams
    >({
      query: ({ id, ...body }) => ({
        url: `contractor-portal/${id}/update-region`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [ApiTags.BUILD_REGIONS],
    }),
  }),
});
