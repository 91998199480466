import { useEffect } from "react";
import LogRocket from "logrocket";
import { useAppSelector } from "../hooks";

type useLogRocketIdentifyProps = {
  userId?: string;
};

const useLogRocketIdentify = ({ userId }: useLogRocketIdentifyProps) => {
  const { isLogRocketInit } = useAppSelector((state) => state.logRocket);

  useEffect(() => {
    if (userId && isLogRocketInit) {
      LogRocket.identify(userId);
    }
  }, [userId, isLogRocketInit]);
};

export { useLogRocketIdentify };
