/** @jsxImportSource @emotion/react */
import { Typography } from "@mui/material";
import { css } from "@emotion/react";
import { FC } from "react";
import { StyleHelpers } from "../constants/styleConstants";

interface Props {
  label: String;
  value: String;
  isOverdue?: boolean;
}

const styles = {
  container: css({
    display: "flex",
    flexDirection: "row",
    margin: "0 5px",
    padding: 0,
    [StyleHelpers.mediaQueries.xsScreen]: {
      width: "100%",
      marginBottom: "5px",
      alignItems: "center",
    },
  }),
};

export const CardLabel: FC<Props> = ({ label, value, isOverdue = false }) => {
  return (
    <div css={styles.container}>
      <Typography sx={{ color: "rgba(0, 0, 0, 0.6)", fontweight: "400" }}>
        {label}
      </Typography>
      <Typography
        sx={{
          marginLeft: "10px",
          fontWeight: "bold",
          color: isOverdue ? "red" : "",
        }}
      >
        {value}
      </Typography>
    </div>
  );
};
