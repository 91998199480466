/** @jsxImportSource @emotion/react */

import { useContext, useEffect, useState } from "react";
import { css } from "@emotion/react/macro";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setLoading } from "../slices/loadingSlice";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { validateEmail } from "../util/validateEmail";
import { CollapsableAlert } from "../components/CollapsableAlert";
import {
  CONTACTOR_LOGIN_STORAGE,
  ContractorLoginStorage,
  yardzenGoogleAuthLogin,
} from "../services/auth";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { SegmentClickTypes, SegmentFlows } from "../util/Segment/constants";
import { useSegment } from "../util/Segment/useSegment";
import { useLazyGetEmailAccessAuthQuery } from "../services/pangaeaService";
import { isYardzenEmail } from "../util/isYardzenEmail";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { getAuth, getRedirectResult } from "firebase/auth";
import { AuthContext } from "../contexts/AuthContext";

const styles = {
  pageContainer: css({
    display: "flex",
    flexDirection: "column",
  }),
  mainContent: css({
    margin: "30vh auto",
  }),
  emailText: css({
    fontSize: "12px",
    minWidth: "300px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  }),
  loginBtn: css({
    marginTop: "10px",
    width: "100%",
  }),
};

export const LoginPage: React.FC<any> = () => {
  const { currentUser } = useContext(AuthContext);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | null>();
  const [success, setSuccess] = useState<boolean>(false);
  const { loading } = useAppSelector((state) => state.loading);
  const dispatch = useAppDispatch();
  const [contractorLogin, setContractorLogin] =
    useLocalStorage<ContractorLoginStorage>(CONTACTOR_LOGIN_STORAGE);
  const navigate = useNavigate();
  const segment = useSegment();
  // const location = useLocation();
  // const state = location.state;
  const [fetchGetEmailAccess, { error: errorGetEmailAccess }] =
    useLazyGetEmailAccessAuthQuery();

  // this catches the redirect from google sign in
  useEffect(() => {
    const debugRedirectResult = async () => {
      try {
        const result = await getRedirectResult(getAuth());
        if (result) {
          navigate("/admin");
        }
      } catch (error) {
        console.error(error);
      }
    };
    debugRedirectResult();
  }, [navigate]);

  useEffect(() => {
    if (currentUser) {
      if (contractorLogin?.contractorId) {
        navigate(`/${contractorLogin?.contractorId}`);
      }
      if (isYardzenEmail(currentUser.email)) {
        navigate("/admin");
      }
    }
  }, [contractorLogin?.contractorId, currentUser, navigate]);

  useEffect(() => {
    if (errorGetEmailAccess) setError((errorGetEmailAccess as Error).message);
  }, [errorGetEmailAccess]);

  const handleLogin = async () => {
    if (validateEmail(email)) {
      let loginError: string =
        "Failed to login with provided email, contact build@yardzen.com";
      try {
        segment.identify(email);
        segment.trackClicked({
          button_name: "Log In",
          click_type: SegmentClickTypes.BUTTON,
          button_content: "Log In",
          flow_name: SegmentFlows.CONTRACTOR_PAGE,
        });
        dispatch(setLoading({ loading: true }));
        if (isYardzenEmail(email)) {
          setContractorLogin({ email: email });
          await yardzenGoogleAuthLogin(email);
        } else {
          fetchGetEmailAccess({ email });
          onSuccess();
        }
        dispatch(setLoading({ loading: false }));
      } catch (e) {
        dispatch(setLoading({ loading: false }));
        onError(loginError);
      }
    } else {
      onError("Please, enter a valid email");
    }
  };

  const onError = (error?: string) => {
    setSuccess(false);
    setError(error);
  };

  const onSuccess = () => {
    setError(null);
    setSuccess(true);
  };

  const onEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleErrorOnClose = () => {
    setError(null);
  };

  const handleSuccessOnClose = () => {
    setSuccess(false);
  };

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Box css={styles.pageContainer}>
      <CollapsableAlert
        severity="error"
        open={!!error}
        title="Error"
        text={error || ""}
        secondsOpen={4}
        onClose={handleErrorOnClose}
      />
      <CollapsableAlert
        severity="success"
        open={success}
        title="Success"
        text="Please check your email for a link to continue accessing the dashboard. If a valid email was provided, you should receive an email in the next 5 minutes, if not please contact build@yardzen.com"
        onClose={handleSuccessOnClose}
      />
      <Box component="main" css={styles.mainContent}>
        <Container>
          <Typography component="h1" variant="h5">
            Log into Yardzen's Contractor Portal
          </Typography>
          <TextField
            label="Email Address"
            variant="standard"
            css={styles.emailText}
            size="small"
            onChange={onEmailChange}
            onKeyDown={(ev) => {
              if (ev.key === "Enter" && !success) handleLogin();
            }}
          />
          <Button
            css={styles.loginBtn}
            onClick={handleLogin}
            size="small"
            variant="contained"
            disabled={success}
          >
            Log in
          </Button>
        </Container>
      </Box>
    </Box>
  );
};
