export const GET_USER_TERMS_OF_SERVICE = `
   query GetUserTermsOfService($input: FindAllUserTermsOfServiceInput!) {
    findTermsOfService(input: $input) {
      tosAgreed
      dateOfAgree
      typeOfTos
      userId
    }
  }
`;

export const CREATE_CURRENT_USER_TERMS_OF_SERVICE = `
  mutation CreateCurrentUserTermsOfService($input: CreateCurrentUserTermsOfServiceInput!) {
    createCurrentUserTermsOfService(input: $input) {
      id
    }
  }
`;

export const GET_ACCEPTED_USER_TERMS_OF_SERVICE = `
  query AcceptedUserTerms($input: FindAllUserTermsOfServiceInput!) {
    acceptedCurrentTermsOfService(input: $input) {
      id
    }
  }
  `;
