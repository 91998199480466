/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react/macro";
import Container from "@mui/material/Container";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useAppSelector } from "../../hooks";
import { AuthContext } from "../../contexts/AuthContext";
import { useGetAllProjectsQuery } from "../../services/pangaeaService";
import { useLogRocketIdentify } from "../../hooks/useLogRocketIdentify";
import { setError } from "../../slices/errorSlice";
import { setLoading } from "../../slices/loadingSlice";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { StyleHelpers } from "../../constants/styleConstants";

const styles = {
  pageContainer: css({
    display: "flex",
    flexDirection: "column",
  }),
  mainContent: css({
    display: "flex",
    flexGrow: "1",
    paddingTop: "30px",
  }),
};

export const DashboardPage: React.FC = () => {
  const { loading } = useAppSelector((state) => state.loading);
  const { errorStatus, errorMessage } = useAppSelector((state) => state.error);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const {
    data: projects,
    error: errorAdminProjects,
    isFetching: isAdminProjectsFetching,
  } = useGetAllProjectsQuery({}, {});
  useLogRocketIdentify({ userId: currentUser?.email || "" });

  useEffect(() => {
    if (errorAdminProjects) {
      dispatch(
        setError({
          errorMessage: (errorAdminProjects as Error).message,
          errorStatus: true,
        })
      );
    }
  }, [errorAdminProjects, dispatch]);

  useEffect(() => {
    dispatch(setLoading({ loading: isAdminProjectsFetching }));
  }, [isAdminProjectsFetching, dispatch]);

  useEffect(() => {
    if (!currentUser) navigate("/login");
  }, [currentUser, navigate]);

  const columns: GridColDef[] = [
    { field: "contractorName", headerName: "Contractor", width: 250 },
    { field: "pipefyId", headerName: "Pipefy Id", width: 130 },
    {
      field: "fullName",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: (params) => (
        <Button
          onClick={() => navigate(`/admin/contractors/${params.row.pipefyId}`)}
        >
          View
        </Button>
      ),
    },
  ];

  if (loading || !projects) {
    return <LoadingSpinner />;
  }

  if (errorStatus) {
    return <h1>{errorMessage}</h1>;
  }

  return (
    <Container css={styles.pageContainer}>
      <Typography
        variant="h3"
        component="h2"
        sx={{
          [StyleHelpers.mediaQueries.xsScreen]: {
            fontSize: "30px",
          },
        }}
      >
        Contractors
      </Typography>

      <Container css={styles.mainContent}>
        <DataGrid
          rows={projects}
          columns={columns}
          disableRowSelectionOnClick
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          autoHeight
          pageSizeOptions={[25, 50, 100]}
          sortModel={[{ field: "contractorName", sort: "asc" }]}
        />
      </Container>
    </Container>
  );
};
