/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react/macro";
import Box from "@mui/material/Box";
import { useContext } from "react";
import { Outlet } from "react-router-dom";

import { AuthContext } from "../contexts/AuthContext";
import { TopNav } from "./TopNav";
import { useLogRocketIdentify } from "../hooks/useLogRocketIdentify";

const styles = {
  pageContainer: css({
    display: "flex",
    flexDirection: "column",
  }),
  mainContent: css({
    display: "flex",
    flexGrow: "1",
    paddingTop: "30px",
  }),
};

export const ContractorLayout: React.FC = () => {
  const { currentUser } = useContext(AuthContext);
  useLogRocketIdentify({ userId: currentUser?.email || "" });

  return (
    <Box css={styles.pageContainer}>
      <Box>
        <TopNav loggedInEmail={currentUser?.email} />
      </Box>
      <Box component="main" css={styles.mainContent}>
        <Outlet></Outlet>
      </Box>
    </Box>
  );
};
