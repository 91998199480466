import React, { useEffect } from "react";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../hooks";
import { removePhase, selectPhase, reset } from "../slices/phaseSlice";
import { Container } from "@mui/system";
import {
  phaseGroups,
  phaseFiltersToDisplay,
  LOST_PHASES,
  IN_PROGRESS_PHASES,
  COMPLETE_PHASES,
  ALL_PHASES,
} from "../constants/phaseGroups";
import { setNoUpdateDue, setUpdateDue } from "../slices/updateSlice";
import { Project } from "../slices/projectSlice";
import { useSegment } from "../util/Segment/useSegment";
import {
  SegmentFlows,
  SegmentForms,
  SegmentInputTypes,
} from "../util/Segment/constants";

export const CheckBoxContainer = ({
  phaseFilteredJobs,
}: {
  phaseFilteredJobs: Project[];
}) => {
  const segment = useSegment();
  const { selectedPhases } = useAppSelector((state) => state.phase);
  const { updateDue, noUpdateDue } = useAppSelector((state) => state.update);
  const [allChecked, setAllChecked] = React.useState(true);
  const [lostChecked, setLostChecked] = React.useState(false);
  const [completeChecked, setCompleteChecked] = React.useState(false);
  const [inProgressChecked, setInProgressChecked] = React.useState(false);
  const dispatch = useAppDispatch();

  const { lostPhases, inProgressPhases, completePhases } = phaseGroups;

  useEffect(() => {
    if (selectedPhases.length) {
      setAllChecked(false);
    } else {
      setAllChecked(true);
    }
  }, [selectedPhases]);

  const phaseCheckBoxes = [
    {
      name: ALL_PHASES,
      label: `All (${phaseFilteredJobs.length})`,
      isChecked: allChecked,
      handleClick: () => {
        if (selectedPhases.length) {
          dispatch(reset());
          setAllChecked(true);
          setCompleteChecked(false);
          setInProgressChecked(false);
          setLostChecked(false);
        }
      },
    },
    {
      name: LOST_PHASES,
      label: `Lost (${
        phaseFilteredJobs.filter((job) =>
          lostPhases.includes(job.current_phase.name)
        ).length
      })`,
      isChecked: lostChecked,
      handleClick: () => {
        if (lostChecked) {
          dispatch(removePhase({ phases: lostPhases }));
          setLostChecked(false);
        } else {
          dispatch(selectPhase({ phases: lostPhases }));
          setLostChecked(true);
        }
      },
    },
    {
      name: IN_PROGRESS_PHASES,
      label: `In Progress (${
        phaseFilteredJobs.filter((job) =>
          inProgressPhases.includes(job.current_phase.name)
        ).length
      })`,
      isChecked: inProgressChecked,
      handleClick: () => {
        if (inProgressChecked) {
          dispatch(removePhase({ phases: inProgressPhases }));
          setInProgressChecked(false);
        } else {
          dispatch(selectPhase({ phases: inProgressPhases }));
          setInProgressChecked(true);
        }
      },
    },
    {
      name: COMPLETE_PHASES,
      label: `Complete (${
        phaseFilteredJobs.filter((job) =>
          completePhases.includes(job.current_phase.name)
        ).length
      })`,
      isChecked: completeChecked,
      handleClick: () => {
        if (completeChecked) {
          dispatch(removePhase({ phases: completePhases }));
          setCompleteChecked(false);
        } else {
          dispatch(selectPhase({ phases: completePhases }));
          setCompleteChecked(true);
        }
      },
    },
  ];

  const updateCheckBoxes = () => [
    {
      name: "Update Needed",
      label: `Update Needed (${
        phaseFilteredJobs.filter((job) => job.due_date).length
      })`,
      isChecked: updateDue,
      handleClick: () => {
        if (updateDue) {
          dispatch(setUpdateDue({ updateDue: false }));
        } else {
          dispatch(setUpdateDue({ updateDue: true }));
        }
      },
    },
    {
      name: "No Update Needed",
      label: `No Update Needed (${
        phaseFilteredJobs.filter((job) => !job.due_date).length
      })`,
      isChecked: noUpdateDue,
      handleClick: () => {
        if (noUpdateDue) {
          dispatch(setNoUpdateDue({ noUpdateDue: false }));
        } else {
          dispatch(setNoUpdateDue({ noUpdateDue: true }));
        }
      },
    },
  ];

  const renderPhaseCheckBoxes = () => {
    return phaseCheckBoxes
      .filter((phase) => {
        return phaseFiltersToDisplay.includes(phase.name);
      })
      .map((checkBox) => {
        return (
          <FormControlLabel
            key={checkBox.label}
            sx={{
              marginLeft: "5px",
            }}
            control={
              <Checkbox
                checked={checkBox.isChecked}
                onClick={() => {
                  checkBox.handleClick();

                  segment.trackFormFieldFilled({
                    flow_name: SegmentFlows.CONTRACTOR_PAGE,
                    field_name: "Stage Filter",
                    form_name: SegmentForms.CONTRACTOR_PAGE_FILTER,
                    input_type: SegmentInputTypes.CHECKBOX,
                    option_selected: checkBox.name,
                  });
                }}
                style={{ paddingLeft: "0px" }}
              />
            }
            label={checkBox.label}
          />
        );
      });
  };

  const renderUpdateCheckBoxes = () => {
    return updateCheckBoxes().map((checkBox) => {
      return (
        <FormControlLabel
          key={checkBox.label}
          sx={{
            marginLeft: "5px",
          }}
          control={
            <Checkbox
              checked={checkBox.isChecked}
              onClick={() => {
                checkBox.handleClick();

                segment.trackFormFieldFilled({
                  flow_name: SegmentFlows.CONTRACTOR_PAGE,
                  field_name: "Update Filter",
                  form_name: SegmentForms.CONTRACTOR_PAGE_FILTER,
                  input_type: SegmentInputTypes.CHECKBOX,
                  option_selected: checkBox.name,
                });
              }}
              style={{ paddingLeft: "0px" }}
            />
          }
          label={checkBox.label}
        />
      );
    });
  };

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "start",
        flexFlow: "column",
        alignItems: "start",
        padding: 0,
        marginTop: "10px",
      }}
    >
      <Typography>Stages</Typography>
      {renderPhaseCheckBoxes()}
      <Typography mt={1}>Update Due</Typography>
      {renderUpdateCheckBoxes()}
    </Container>
  );
};
