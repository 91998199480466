import { UpdateContractor } from "../types/contractors";

export function mapContractorFieldsForUpdate(
  contractor: UpdateContractor,
  contractorId: string
): UpdateContractor {
  contractor = setEmptyStringsToNull(contractor) as UpdateContractor;
  contractor.id = contractorId;
  return contractor;
}

/**
 * Sets all "" values to null in a Contractor object.
 * This is necessary because the API does not accept "" values for certain fields with validation, such as website.
 * If we don't want a field to have a value, we need to send null.
 * Undefined values get stripped out by the API, so we can't use that.
 * @param contractor Contractor object to modify
 */
export function setEmptyStringsToNull(
  contractor: UpdateContractor
): UpdateContractor {
  const contractorCopy: any = { ...contractor };
  for (const [key, value] of Object.entries(contractorCopy)) {
    if (value === "") {
      contractorCopy[key] = null;
    }
  }
  return contractorCopy;
}
