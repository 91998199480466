export enum SegmentFlows {
  CONTRACTOR_PAGE = "Contractor Page",
}

export enum SegmentSubFlows {}

export enum SegmentForms {
  CONTRACTOR_PAGE_FILTER = "Contractor Page Filter",
}

export enum SegmentInputTypes {
  TEXT = "Text",
  SELECT = "Select",
  MULTI_SELECT = "Multi Select",
  RADIO_GROUP = "Radio Group",
  CHECKBOX = "Checkbox",
}

export enum SegmentClickTypes {
  BUTTON = "Button",
  NAV = "Nav",
  CONTACT = "Contact",
  FOOTER = "Footer",
  CAROUSEL = "Carousel",
  HYPERLINK = "Hyperlink",
  BREADCRUMB = "Breadcrumb",
}

export enum SegmentExperimentTypes {}
