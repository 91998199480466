export const GET_BUILD_REGION_BY_ID = `
  query GetBuildRegionById($input: FindOneBuildRegionInput!){
    buildRegion(input: $input) {
      id
      displayName
      isDefault
      areaGeometry
      minLat
      maxLat
      minLng
      maxLng
    }
  }
`;

export const UPDATE_BUILD_REGION_BY_ID = `
  mutation UpdateBuildRegion($input: UpdateBuildRegionInput!){
    updateBuildRegion(updateBuildRegionInput: $input) {
      ok
    }
  }
`;

export const CREATE_BUILD_REGION = `
  mutation CreateBuildRegion($input: CreateBuildRegionInput!){
    createBuildRegion(createBuildRegionInput: $input) {
      id
    }
  }
`;

export const GET_BUILD_REGIONS_BY_PROJECT_LOCATION = `
  query GetBuildRegionsByProjectCoordinates($input: FindAllBuildRegionInput!){
    buildRegions(input: $input) {
      id
    }
  }
`;
