import ReactDOM from "react-dom/client";
import { App } from "./App";
import { initializeFirebase } from "./util/firebase/initializeFirebase";

initializeFirebase();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
