import { useEffect } from "react";
import LogRocket from "logrocket";
import { setIsLogRocketInit } from "../slices/logRocketSlice";
import { useAppDispatch } from "../hooks";

/**
 * Initializes log rocket for the app
 */
const useLogRocketInit = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function init() {
      try {
        await LogRocket.init("snab0e/liisa");
        dispatch(setIsLogRocketInit({ isLogRocketInit: true }));
      } catch (e) {
        console.error("Error initiating LogRocket! ", e);
      }
    }
    if (process.env.NODE_ENV === "production") {
      init();
    }
  }, [dispatch]);
};

export { useLogRocketInit };
