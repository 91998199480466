import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface Field {
  name: string;
  value: string;
  report_value?: string;
}

export interface ContractorState {
  title: string;
  fields: [Field] | [];
}

const initialState: ContractorState = {
  title: "",
  fields: [],
};

export const contractorSlice = createSlice({
  name: "contractor",
  initialState,
  reducers: {
    setContractor: (state, action: PayloadAction<ContractorState>) => {
      state.fields = action.payload.fields;
      state.title = action.payload.title;
    },
  },
});

export const { setContractor } = contractorSlice.actions;

export default contractorSlice.reducer;
