/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react/macro";
import Container from "@mui/material/Container";
import { BuildRegionsContainer } from "../components/BuildRegionsContainer";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { processProjectDataFromCard } from "../services/projects";
import { useAppSelector } from "../hooks";
import { setLoading } from "../slices/loadingSlice";
import { setError } from "../slices/errorSlice";
import { useLogRocketIdentify } from "../hooks/useLogRocketIdentify";
import { useLocalStorage } from "../hooks/useLocalStorage";
import {
  CONTACTOR_LOGIN_STORAGE,
  ContractorLoginStorage,
} from "../services/auth";
import { useSegment } from "../util/Segment/useSegment";
import {
  useGetContractorByEmailQuery,
  useLazyGetProjectCardQuery,
  useUpdateContractorMutation,
} from "../services/pangaeaService";
import {
  useGetBuildRegionByIdQuery,
  useCreateBuildRegionMutation,
  useUpdateBuildRegionMutation,
} from "../services/banyanService";
import { setContractor } from "../slices/contractorSlice";
import { setProjects } from "../slices/projectSlice";
import { setFirebaseContractor } from "../slices/firebaseContractor";
import { isYardzenEmail } from "../util/isYardzenEmail";
import { AuthContext } from "../contexts/AuthContext";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { setBuildRegion, setCurrentAreaGeometry, setDefaultAreaGeometry } from "../slices/buildRegions";
import {
  BuildRegion,
  RegionCoordinates,
} from "../types/buildRegions";
import { mapContractorFieldsForUpdate } from "../util/mapContractorFieldsForUpdate";
import { UpdateContractor } from "../types/contractors";
import { Box } from "@mui/material";
import { CoverageAreaInstructions } from "../components/CoverageAreaInstructions";
import { filterInvalidPolygons } from "../util/BuildRegions/filterInvalidPolygons";
import { composeBuildRegionUpdateInput } from "../util/BuildRegions/buildRegions";

const styles = {
  pageContainer: css({
    display: "flex",
    flexDirection: "column",
  }),
  mainContent: css({
    display: "flex",
    flexGrow: "1",
    paddingTop: "30px",
  }),
};

export const ServiceArea: React.FC = () => {
  const contractorLogin = useLocalStorage<ContractorLoginStorage>(
    CONTACTOR_LOGIN_STORAGE
  )[0];
  const { loading } = useAppSelector(
    (state: { loading: any }) => state.loading
  );
  const { errorStatus, errorMessage } = useAppSelector(
    (state: { error: any }) => state.error
  );
  const { currentAreaGeometry, defaultAreaGeometry } = useAppSelector(
    (state: any) => state.buildRegions
  );

  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const segment = useSegment();
  const { currentUser } = useContext(AuthContext);

  const [updatingContractorData, setUpdatingContractorData] =
    useState<boolean>(false);

  const {
    data: contractorByEmailData,
    isFetching: isContractorByEmailFetching,
  } = useGetContractorByEmailQuery(contractorLogin?.email ?? "");

  const { data: contractorInitialBuildRegion } = useGetBuildRegionByIdQuery(
    {
      id: contractorByEmailData?.initialBuildRegionId ?? "",
    },
    {
      skip: !contractorByEmailData?.initialBuildRegionId,
    }
  );

  const { data: contractorCurrentBuildRegion } = useGetBuildRegionByIdQuery(
    {
      id: contractorByEmailData?.currentBuildRegionId ?? "",
    },
    {
      skip: !contractorByEmailData?.currentBuildRegionId,
    }
  );

  const [updateContractorBuildRegion] = useUpdateBuildRegionMutation();

  const [createContractorBuildRegion] = useCreateBuildRegionMutation();

  const [updateContractor] = useUpdateContractorMutation();

  const [
    fetchGetProjectCard,
    { data, error: errorProjectCardProfile, isFetching: isProjectCardFetching },
  ] = useLazyGetProjectCardQuery();
  const { contractor, projects } = processProjectDataFromCard(data);
  useLogRocketIdentify({ userId: currentUser?.email || "" });

  useEffect(() => {
    if (errorProjectCardProfile)
      dispatch(
        setError({
          errorMessage: (errorProjectCardProfile as Error).message,
          errorStatus: true,
        })
      );
  }, [errorProjectCardProfile, dispatch]);

  useEffect(() => {
    dispatch(setLoading({ loading: isProjectCardFetching }));
  }, [isProjectCardFetching, dispatch]);

  useEffect(() => {
    dispatch(setLoading({ loading: isContractorByEmailFetching }));
  }, [isContractorByEmailFetching, dispatch]);

  useEffect(() => {
    if (contractor) dispatch(setContractor(contractor));
  }, [contractor, dispatch]);

  useEffect(() => {
    if (projects) dispatch(setProjects({ projects }));
  }, [projects, dispatch]);

  useEffect(() => {
    if (contractorByEmailData)
      dispatch(
        setFirebaseContractor({ firebaseContractor: contractorByEmailData })
      );
  }, [contractorByEmailData, dispatch]);

  useEffect(() => {
    if (contractorInitialBuildRegion && contractorCurrentBuildRegion) {
      dispatch(
        setBuildRegion({
          initialBuildRegion: contractorInitialBuildRegion,
          currentBuildRegion: contractorCurrentBuildRegion,
        })
      );
    }
  }, [contractorInitialBuildRegion, contractorCurrentBuildRegion, dispatch]);

  useEffect(() => {
    if (contractorLogin?.contractorId !== id) {
      if (isYardzenEmail(currentUser?.email || "")) {
        // Redirect Yardzen employees to authorize page to reset the contractor id
        return navigate(`/authorize?contractorId=${id}`);
      } else {
        // Redirect to authorized contractor id if not a Yardzen employee
        return navigate(`/${contractorLogin?.contractorId}`);
      }
    }
    fetchGetProjectCard({ contractorId: id });
    if (contractorLogin?.email) {
      segment.identify(contractorLogin.email, {
        conractorId: contractorLogin?.contractorId,
      });
    }
  }, [
    id,
    navigate,
    fetchGetProjectCard,
    contractorLogin,
    segment,
    currentUser?.email,
  ]);

  useEffect(() => {
    if (contractorCurrentBuildRegion) {
      dispatch(
        setCurrentAreaGeometry({
          currentAreaGeometry: JSON.parse(contractorCurrentBuildRegion?.areaGeometry)
        })
      );
    }
  }, [contractorCurrentBuildRegion, dispatch]);

  useEffect(() => {
    if (contractorInitialBuildRegion) {
      dispatch(
        setDefaultAreaGeometry({
          defaultAreaGeometry: JSON.parse(contractorInitialBuildRegion?.areaGeometry)
        })
      );
    }
  }, [contractorInitialBuildRegion, dispatch]);

  async function handleUpdateContractor(
    regionCoordinates: RegionCoordinates[]
  ) {
    setUpdatingContractorData(true);

    const updatedContractor = mapContractorFieldsForUpdate(
      contractorByEmailData as UpdateContractor,
      contractorByEmailData?.id ?? ""
    ) as UpdateContractor;

    const filteredRegionCoordanates = filterInvalidPolygons(regionCoordinates);

    // Check at least one polygon is drawn in the map
    if (filteredRegionCoordanates.length === 0) {
      alert("At least one build region must be set on the map before update");
      setUpdatingContractorData(false);
      return;
    }

    try {
      // call createBuildRegion only if currentBuildRegion (obtained from contractor response) is default or empty.
      // if not, call updateBuildRegion.
      // On creation, it returns the new created object with id, that id is used to update the contractor.
      if (
        (contractorCurrentBuildRegion &&
          contractorCurrentBuildRegion?.isDefault) ||
        !contractorCurrentBuildRegion ||
        !contractorCurrentBuildRegion?.id
      ) {
        const areaGeometry = JSON.stringify(
          composeBuildRegionUpdateInput({
            ...defaultAreaGeometry,
            coordinates: filteredRegionCoordanates,
          })
        );
        const newBuildRegionBody = {
          displayName:
            contractorCurrentBuildRegion?.displayName ??
            `Custom_Region_${updatedContractor.id}`,
          internalName: `custom_region_${updatedContractor.id.toLowerCase()}`,
          areaGeometry,
          isDefault: false,
          contractorId: updatedContractor.id,
          lastUpdatedBy: currentUser?.email || "",
          archived: false,
        } as BuildRegion & { updatedByEmail: string };

        if (filteredRegionCoordanates.length) {
          const newBuildRegion = await createContractorBuildRegion(
            newBuildRegionBody
          );

          updatedContractor.currentBuildRegionId =
            (newBuildRegion as { data: BuildRegion }).data.id ??
            updatedContractor.initialBuildRegionId;
        }

        // TODO: show error creating build region (already exists, contact support)
      } else {
        if (filteredRegionCoordanates.length) {
          const areaGeometry = JSON.stringify(
            composeBuildRegionUpdateInput({
              ...currentAreaGeometry,
              coordinates: filteredRegionCoordanates,
            })
          );
          const updatedBuildRegionBody = {
            id: contractorCurrentBuildRegion.id,
            lastUpdatedBy: currentUser?.email || "",
            areaGeometry,
          };

          dispatch(
            setCurrentAreaGeometry({
              currentAreaGeometry: { 
                ...currentAreaGeometry, 
                coordinates: filteredRegionCoordanates 
              }
            })
          );

          await updateContractorBuildRegion(updatedBuildRegionBody);
        }
        updatedContractor.currentBuildRegionId =
          contractorCurrentBuildRegion?.id ?? "";
      }

      await updateContractor(updatedContractor);
    } catch (error) {
      console.error(error);
    } finally {
      setUpdatingContractorData(false);
    }
  }

  const renderBody = () => {
    if (loading) {
      return <LoadingSpinner />;
    }

    if (errorStatus) {
      return <h1>{errorMessage}</h1>;
    }

    return (
      <Box>
        <CoverageAreaInstructions />
        <BuildRegionsContainer
          handleUpdateContractorData={handleUpdateContractor}
          isUpdating={updatingContractorData}
        />
      </Box>
    );
  };

  return <Container css={styles.pageContainer}>{renderBody()}</Container>;
};
