import { configureStore } from "@reduxjs/toolkit";
import projectsReducer from "./slices/projectSlice";
import clientReducer from "./slices/clientSlice";
import contractorReducer from "./slices/contractorSlice";
import firebaseContractorReducer from "./slices/firebaseContractor";
import buildRegionsReducer from "./slices/buildRegions";
import loadingReducer from "./slices/loadingSlice";
import errorReducer from "./slices/errorSlice";
import phaseReducer from "./slices/phaseSlice";
import updateReducer from "./slices/updateSlice";
import logRocketReducer from "./slices/logRocketSlice";
import { pangaea } from "./api/v1/pangaea";
import { banyanAPI } from "./api/v1/banyanV1API"

export const store = configureStore({
  reducer: {
    projects: projectsReducer,
    client: clientReducer,
    contractor: contractorReducer,
    firebaseContractor: firebaseContractorReducer,
    buildRegions: buildRegionsReducer,
    loading: loadingReducer,
    error: errorReducer,
    phase: phaseReducer,
    update: updateReducer,
    logRocket: logRocketReducer,
    [pangaea.reducerPath]: pangaea.reducer,
    [banyanAPI.reducerPath]: banyanAPI.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(pangaea.middleware).concat(banyanAPI.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
