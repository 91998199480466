/** @jsxImportSource @emotion/react */
import { FC, useState } from "react";
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { StyleHelpers } from "../constants/styleConstants";

import { JobList } from "./JobList";
import { Filter } from "./Filter";
import { filterJobs } from "../util/filterJobs";
import { useAppSelector } from "../hooks";
import { Project } from "../slices/projectSlice";
import { CheckBoxContainer } from "./CheckBoxContainer";
import {
  baseInProgressPhases,
  buildMarketplacePhases,
} from "../constants/phaseGroups";

const styles = {
  header: css({
    borderBottom: "1px solid rgba(0,0,0,0.15)",
  }),
  content: css({
    display: "flex",
    flexDirection: "row",
    padding: "15px 0",
    [StyleHelpers.mediaQueries.smallScreen]: {
      flexDirection: "column",
    },
  }),
  filterCol: css({
    flex: "1 1 auto",
    minWidth: "170px",
    marginRight: "10px",
    [StyleHelpers.mediaQueries.smallScreen]: {
      marginBottom: "20px",
    },
  }),
  listCol: css({
    flex: "3 1 auto",
  }),
};

const JobListContainer: FC = () => {
  const { projects } = useAppSelector((state) => state.projects);
  const { selectedPhases } = useAppSelector((state) => state.phase);
  const { updateDue, noUpdateDue } = useAppSelector((state) => state.update);
  const [filteredJobList, setFilteredJobList] = useState<Project[]>(projects);
  const [searchText, setSearchText] = useState<string>();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    if (!term) {
      setFilteredJobList(projects);
      return;
    }

    setSearchText(term);
    const results = filterJobs({ fullJobList: projects, searchText });
    setFilteredJobList(results);
  };

  const sortedJobs = [...filteredJobList].sort((a, b) => {
    if (!a.due_date) {
      return 1;
    }
    if (!b.due_date) {
      return -1;
    }

    const aDate = new Date(a.due_date);
    const bDate = new Date(b.due_date);
    return aDate.getTime() - bDate.getTime();
  });

  const phaseFilteredJobs = sortedJobs.filter((job) => {
    // we only want to show jobs with specific in progress phases at this time
    return [...baseInProgressPhases, ...buildMarketplacePhases].includes(
      job.current_phase.name
    );
  });

  const selectedPhaseFilteredJobs = phaseFilteredJobs.filter((job) => {
    if (selectedPhases.length === 0) return true;

    return selectedPhases.includes(job.current_phase.name);
  });

  const updateFilteredJobs = selectedPhaseFilteredJobs.filter((job) => {
    if (updateDue && noUpdateDue) return true;
    if (updateDue) return job.due_date;
    if (noUpdateDue) return !job.due_date;
    return true;
  });

  return (
    <>
      <Typography variant="h5" component="h2" css={styles.header}>
        Jobs
      </Typography>
      <div css={styles.content}>
        <div css={styles.filterCol}>
          <Filter onFilterChange={handleSearch} />
          <CheckBoxContainer phaseFilteredJobs={phaseFilteredJobs} />
        </div>
        <div css={styles.listCol}>
          <JobList jobs={updateFilteredJobs} />
        </div>
      </div>
    </>
  );
};

export { JobListContainer };
