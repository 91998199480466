/** @jsxImportSource @emotion/react */

import { ChangeEvent, FC } from "react";
import { TextField } from "@mui/material";
import { useSegment } from "../util/Segment/useSegment";
import {
  SegmentFlows,
  SegmentForms,
  SegmentInputTypes,
} from "../util/Segment/constants";

interface Props {
  onFilterChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Filter: FC<Props> = ({ onFilterChange }) => {
  const segment = useSegment();

  return (
    <div>
      <TextField
        label="Search"
        variant="outlined"
        helperText="Search by name or address"
        onChange={onFilterChange}
        onBlur={(e) => {
          segment.trackFormFieldFilled({
            form_name: SegmentForms.CONTRACTOR_PAGE_FILTER,
            field_name: "Job Search Bar",
            flow_name: SegmentFlows.CONTRACTOR_PAGE,
            input_type: SegmentInputTypes.TEXT,
            text_value: e.target.value,
          });
        }}
      />
    </div>
  );
};
