import { Box, Button, CardActions } from "@mui/material";
import React, { FC } from "react";
import { StyleHelpers } from "../constants/styleConstants";
import { SegmentClickTypes, SegmentFlows } from "../util/Segment/constants";
import { useSegment } from "../util/Segment/useSegment";

interface CardSubheaderProps {
  name: string;
  mediaLink: string;
  handleMediaClick: () => void;
}

export const CardSubheader: FC<CardSubheaderProps> = ({
  name,
  mediaLink,
  handleMediaClick,
}) => {
  const segment = useSegment();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        [StyleHelpers.mediaQueries.xsScreen]: {
          flexDirection: "column",
          alignItems: "flex-start",
        },
      }}
    >
      <Box>{name}</Box>
      <CardActions
        sx={{
          [StyleHelpers.mediaQueries.xsScreen]: {
            padding: "8px 0",
          },
        }}
      >
        <Button
          onClick={() => {
            handleMediaClick();
            segment.trackClicked({
              button_name: "Shared Media",
              click_type: SegmentClickTypes.BUTTON,
              button_content: "Shared Media Link",
              flow_name: SegmentFlows.CONTRACTOR_PAGE,
            });
          }}
          size="small"
          disabled={!mediaLink}
          sx={{
            [StyleHelpers.mediaQueries.xsScreen]: {
              padding: "0",
            },
          }}
        >
          Design Detail Link
        </Button>
      </CardActions>
    </Box>
  );
};
