/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { useCreateCurrentUserTermsOfServiceMutation } from "../../services/banyanService";
import { css } from "@emotion/react";
import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";

interface AcceptTermsProps {
  userId: string;
}

const styles = {
  linkText: css({
    cursor: "pointer",
    color: "#1976d2",
    textDecoration: "underline",
    fontWeight: 500,
  }),
  button: css({
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
  }),
  checkboxContainer: css({
    display: "flex",
    flexDirection: "column",
  }),
};

export function AcceptTermsForm(props: AcceptTermsProps) {
  const [tosChecked, setTosChecked] = useState(false);

  const [createTermsOfService] = useCreateCurrentUserTermsOfServiceMutation();

  const handleSubmission = async () => {
    try {
      await createTermsOfService({
        userId: props.userId,
        tosAgreed: tosChecked,
        dateOfAgree: new Date().toISOString(),
      });

      window.location.reload();
    } catch (error) {
      console.error("Error saving terms of service agreement", error);
    }
  };

  const termsText = (
    <Typography variant="body2">
      I agree to Yardzen's{" "}
      <a
        href="https://yardzen.com/electronic-signature-disclosures"
        rel="noreferrer"
        target="_blank"
        css={styles.linkText}
      >
        Consent to Electronic Signature and Disclosures,
      </a>{" "}
      <a
        href="https://yardzen.com/terms-and-conditions"
        rel="noreferrer"
        target="_blank"
        css={styles.linkText}
      >
        Terms of Service
      </a>{" "}
      and{" "}
      <a
        href="https://yardzen.com/privacy-policy"
        rel="noreferrer"
        target="_blank"
        css={styles.linkText}
      >
        Privacy Policy
      </a>
      .
    </Typography>
  );

  return (
    <div css={styles.checkboxContainer}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={tosChecked}
            onChange={(e) => setTosChecked(e.target.checked)}
            id="terms-checkbox"
            style={{ padding: "3px 9px" }}
          />
        }
        label={termsText}
      />
      <div css={styles.button}>
        <Button disabled={!tosChecked} onClick={handleSubmission}>
          Continue
        </Button>
      </div>
    </div>
  );
}
