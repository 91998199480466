import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AreaGeometry, BuildRegion } from "../types/buildRegions";

const initialState: BuildRegionState = {
  initialBuildRegion: {
    id: "",
    internalName: "",
    displayName: "",
    areaGeometry: "",
    minLat: 0,
    minLng: 0,
    maxLat: 0,
    maxLng: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    lastUpdatedBy: "", // User ID
    archived: false,
    isDefault: false,
    contractorId: "",
  },
  currentBuildRegion: {
    id: "",
    internalName: "",
    displayName: "",
    areaGeometry: "",
    minLat: 0,
    minLng: 0,
    maxLat: 0,
    maxLng: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    lastUpdatedBy: "", // User ID
    archived: false,
    isDefault: false,
    contractorId: "",
  },
  currentAreaGeometry: {
    type: "",
    coordinates: []
  },
  defaultAreaGeometry: {
    type: "",
    coordinates: []
  }
};

export interface BuildRegionState {
  initialBuildRegion?: BuildRegion;
  currentBuildRegion?: BuildRegion;
  currentAreaGeometry?: AreaGeometry;
  defaultAreaGeometry?: AreaGeometry;
}

export const buildRegionsSlice = createSlice({
  name: "buildRegions",
  initialState,
  reducers: {
    setBuildRegion: (state, action: PayloadAction<BuildRegionState>) => {
      state.initialBuildRegion = action.payload.initialBuildRegion;
      state.currentBuildRegion = action.payload.currentBuildRegion;
    },
    setCurrentAreaGeometry: (state, action: PayloadAction<BuildRegionState>) => {
      state.currentAreaGeometry = action.payload.currentAreaGeometry;
    },
    setDefaultAreaGeometry: (state, action: PayloadAction<BuildRegionState>) => {
      state.defaultAreaGeometry = action.payload.defaultAreaGeometry;
    }
  },
});

export const {
  setBuildRegion,
  setCurrentAreaGeometry,
  setDefaultAreaGeometry
} = buildRegionsSlice.actions;

export default buildRegionsSlice.reducer;
