import { css } from "@emotion/react/macro";
import { Container, Link, Typography } from "@mui/material";

const styles = {
  mainContent: css({
    display: "flex",
    flexGrow: "1",
    paddingTop: "30px",
  }),
  listItem: css({}),
};

export const CoverageAreaInstructions: React.FC = () => {
  return (
    <Container>
      <Typography variant="body1" component="p" css={styles.mainContent}>
        Below is a map of the coverage area you service for Yardzen projects.
        Please be cautious when changing as this will directly impact the
        projects that are sent for you to review. Areas shaded in blue are your
        current coverage areas.
      </Typography>
      <br />
      <Typography variant="body1" component="p">
        How to update coverage:{" "}
        <Link
          underline="always"
          target="_blank"
          href="https://www.loom.com/share/b0359d87f6424af69a683cc4b1826f40?sid=c1454674-803f-470d-9052-942df05804f4"
        >
          View video tutorial here
        </Link>
      </Typography>
      <br />
      <Typography component="ol" variant="body1">
        <li css={styles.listItem}>
          <Typography variant="body1">
            Zoom in to the area that you want to select as your coverage
          </Typography>
        </li>
        <li css={styles.listItem}>
          <Typography variant="body1">
            To select your area, click once to drop your first pin, then click
            again to drop other pins and start to “draw” shapes that represent
            your coverage area(s)
          </Typography>
        </li>
        <li css={styles.listItem}>
          <Typography variant="body1">
            To finish and save, you must connect back to the original pin you
            started with. Be sure that you see the pointer finger to close the
            shape.
          </Typography>
        </li>
        <li css={styles.listItem}>
          Feel free to repeat and create multiple “shapes” for any service areas
          that might not be continuous. Do not overlap any of the shapes however
        </li>
        <li css={styles.listItem}>
          To delete a shape, simply right click on it and the blue shade will go
          away.
        </li>
      </Typography>
      <br />
      <Typography>
        If you have issues when trying to update coverage, please call, text or
        email the Pro-support team: (415) 944-9354,{" "}
        <Link underline="always" href="mailto:pro-network@yardzen.com">
          pro-network@yardzen.com
        </Link>
      </Typography>
    </Container>
  );
};
