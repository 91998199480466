/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { JSX } from "@emotion/react/jsx-runtime";
import { Box, Button } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { LoadingSpinner } from "./LoadingSpinner";
import { useLocalStorage } from "../hooks/useLocalStorage";
import {
  CONTACTOR_LOGIN_STORAGE,
  ContractorLoginStorage,
} from "../services/auth";
import { isYardzenEmail } from "../util/isYardzenEmail";
import { logout } from "../util/logout";
import { mustAcceptNewTermsOfService } from "../util/mustAcceptNewTermsOfService";
import { TermsOfService } from "./termsOfService/TermsOfService";

const styles = {
  pageContainer: css({
    display: "flex",
    flexDirection: "column",
    marginTop: "200px",
  }),
};

export const ProtectedRoute = ({
  children,
  adminOnly = false,
}: {
  children: JSX.Element;
  adminOnly?: boolean;
}) => {
  const { currentUser, loading } = useContext(AuthContext);
  const { id } = useParams();
  const [contractorLogin, setContractorLogin] =
    useLocalStorage<ContractorLoginStorage>(CONTACTOR_LOGIN_STORAGE);
  const [showNewTermsOfService, setShowNewTermsOfService] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const adminUser = isYardzenEmail(currentUser?.email || "");

  if (loading)
    return (
      <Box css={styles.pageContainer}>
        <LoadingSpinner />
      </Box>
    );

  if (!currentUser) navigate("/login", { state: { id } });

  // If already signed in to a yardzen account
  if (adminUser && !contractorLogin) {
    setContractorLogin({ contractorId: id, email: currentUser?.email });
    navigate("/admin");
  }

  if (currentUser && !adminUser) {
    mustAcceptNewTermsOfService().then((result) => {
      setShowNewTermsOfService(result);
    });
  }

  // if already signed into a non-yardzen account, but no id in local storage
  if (currentUser && !contractorLogin) {
    return (
      <Box m={2}>
        <Box mb={1}>Something went wrong, please logout and log back in</Box>
        <Button variant="contained" onClick={logout}>
          Logout
        </Button>
      </Box>
    );
  }

  if (adminOnly && !adminUser) navigate("/", { state: { id } });

  if (showNewTermsOfService && currentUser?.uid) {
    return <TermsOfService userId={currentUser?.uid} />;
  }

  return children;
};
