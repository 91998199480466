import { memo, useEffect, useMemo, useRef, useState } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  Polygon,
  GoogleMap,
} from "react-google-maps";
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";
import { CoordinatePair, RegionBoundingBox, RegionCoordinates } from "../types/buildRegions";
import {
  formatBuildRegionsData,
  getZoomLevel,
  initialMapZoom,
} from "../util/BuildRegions/buildRegions";
import { createRoot } from "react-dom/client";

interface IMapWithDraw {
  defaultRegions: RegionCoordinates[];
  existingRegions: RegionCoordinates[];
  buildRegionsBoundingBox: RegionBoundingBox;
  onSavePolygonDraw: (coordinates: RegionCoordinates[]) => void;
}

export const MemoizedMapWithDraw = memo(
  ({
    defaultRegions,
    existingRegions,
    buildRegionsBoundingBox,
    onSavePolygonDraw,
  }: IMapWithDraw) => {
    const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`;
    const mapRef = useRef(null);

    const loadingElement = (
      <div style={{ height: `100%` }} className="loadingElement" />
    );

    const containerElement = useMemo(
      () => <div style={{ height: `400px` }} className="containerElement" />,
      []
    );

    const mapElement = (
      <div style={{ height: `100%` }} className="mapElement" />
    );

    const defaultRegionsOptions = {
      polygonOptions: {
        fillColor: "#e86800",
        fillOpacity: 0,
        strokeWeight: 1.8,
        clickable: true,
        editable: false,
        delete: false,
        zIndex: 0,
      },
    };

    const defaultNewRegionsOptions = {
      drawingControl: true,
      drawingControlOptions: {
        position: window.google?.maps.ControlPosition.TOP_CENTER,
        drawingModes: [window.google?.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        fillColor: "#2146ff",
        fillOpacity: 0.5,
        strokeWeight: 1.7,
        clickable: true,
        editable: false,
        delete: true,
        zIndex: 20000,
      },
    };

    const mapCenterCoordinates = useRef<{
      lat: number;
      lng: number;
    }>(
      buildRegionsBoundingBox && {
        lat:
          (buildRegionsBoundingBox.maxLat + buildRegionsBoundingBox.minLat) / 2,
        lng:
          (buildRegionsBoundingBox.maxLng + buildRegionsBoundingBox.minLng) / 2,
      }
    );

    const mapZoom = useRef(10);

    const shapesCoordinates = useRef<any[]>([]);

    const drawedShapes = useRef<Array<google.maps.Polygon>>([]);

    const [defaultBuildRegion, setDefaultBuildRegion] = useState<
      RegionCoordinates[]
    >([]);

    const [existingShapesCoordinates, setExistingShapesCoordinates] = useState<
      RegionCoordinates[]
    >([]);

    useEffect(() => {
      if (defaultRegions !== defaultBuildRegion) {
        setDefaultBuildRegion(defaultRegions as RegionCoordinates[]);
      }
      if (existingRegions !== existingShapesCoordinates) {
        setExistingShapesCoordinates(existingRegions);
      }
    }, [defaultRegions, existingRegions, defaultBuildRegion, existingShapesCoordinates]);

    useEffect(() => {
      const tempDiv = document.createElement("div");
      tempDiv.style.visibility = "hidden";
      tempDiv.style.height = "0";
      tempDiv.style.overflow = "hidden";

      createRoot(tempDiv).render(containerElement);
      document.body.appendChild(tempDiv);

      const width = tempDiv.offsetWidth;
      document.body.removeChild(tempDiv);
      mapZoom.current =
        buildRegionsBoundingBox && containerElement?.props?.style?.height
          ? getZoomLevel(
              buildRegionsBoundingBox,
              width,
              containerElement?.props?.style?.height.slice(0, -2)
            )
          : initialMapZoom;
    }, [buildRegionsBoundingBox, containerElement]);

    function getCoordinatesFromPolygon(polygon: google.maps.Polygon) {
      const len = polygon.getPath().getLength();
      const path = polygon.getPath();

      const coordinates: CoordinatePair = [];
      for (let i = 0; i < len; i++) {
        coordinates.push([path.getAt(i).lng(), path.getAt(i).lat()]);
      }
      // filter points and lines
      if (coordinates.length < 3) return null;

      return coordinates;
    }

    function handleOverlayComplete(e: any) {
      const shape = e.overlay;
      const path = e.overlay.getPath();
      const firstPoint = path.getAt(0);
      shape.type = e.type;
      path.push(firstPoint);

      const coordinates = getCoordinatesFromPolygon(shape);
      const existingShapesCoordinatesArrayCopy = [...existingShapesCoordinates];
      if (coordinates) {
        shapesCoordinates.current.push(coordinates);
        drawedShapes.current.push(shape);
        existingShapesCoordinatesArrayCopy.push([coordinates]);
      }
      setExistingShapesCoordinates(existingShapesCoordinatesArrayCopy);
      onSavePolygonDraw(existingShapesCoordinatesArrayCopy);
    }

    function handleDeleteLoadedShape(coordinates: any) {
      const existingShapesCoordinatesArrayCopy = [...existingShapesCoordinates];
      const index = existingShapesCoordinatesArrayCopy.indexOf(coordinates);
      if (index !== -1) {
        existingShapesCoordinatesArrayCopy.splice(index, 1);
        setExistingShapesCoordinates(existingShapesCoordinatesArrayCopy);
        onSavePolygonDraw(existingShapesCoordinatesArrayCopy);
      }
    }

    function handleMapLoad(map: any) {
      mapRef.current = map; // Set the current map instance to the ref
    }

    const MapWithDrawingManager = compose(
      withProps({
        googleMapURL,
        loadingElement,
        containerElement,
        mapElement,
        existingShapesCoordinates
      }),
      withScriptjs,
      withGoogleMap
    )((props: any) => (
      <div style={{ height: "400px" }} className="containerElement">
        {/* @ts-ignore */}
        <GoogleMap
          ref={handleMapLoad}
          defaultZoom={mapZoom.current ?? 7}
          defaultCenter={
            new window.google.maps.LatLng(
              mapCenterCoordinates.current ?? { lat: 0, lng: 0 }
            )
          }
          onCenterChanged={() =>
            (mapCenterCoordinates.current = {
              lat: (
                {
                  ...{ ...(mapRef.current as any).context },
                } as any
              ).__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.center.lat(),
              lng: (
                {
                  ...{ ...(mapRef.current as any).context },
                } as any
              ).__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.center.lng(),
            })
          }
          onZoomChanged={() =>
            (mapZoom.current = (
              {
                ...{ ...(mapRef.current as any).context },
              } as any
            ).__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.zoom)
          }
        >
          <DrawingManager
            defaultDrawingMode={window.google.maps.drawing.OverlayType.POLYGON}
            defaultOptions={defaultNewRegionsOptions}
            onOverlayComplete={handleOverlayComplete}
          />
          {existingShapesCoordinates?.map((coordinates, index) => (
            <Polygon
              defaultOptions={defaultNewRegionsOptions.polygonOptions}
              key={index}
              paths={formatBuildRegionsData(coordinates)}
              onRightClick={(e: any) => handleDeleteLoadedShape(coordinates)}
            />
          ))}
          {/* These are the default build regions */}
          {defaultBuildRegion?.map((coordinates, index) => (
            <Polygon
              defaultOptions={defaultRegionsOptions.polygonOptions}
              key={index}
              paths={formatBuildRegionsData(coordinates)}
            />
          ))}
        </GoogleMap>
      </div>
    ));

    return <MapWithDrawingManager />;
  }
);
