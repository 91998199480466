import { FC, useContext, ReactNode, useEffect, useState } from "react";
import { SplitProvider, TrafficTypes } from "@yardzen-inc/react-split";
import { WaitForSplitClient } from "./WaitForSplitClient";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { AuthContext } from "../../contexts/AuthContext";

interface SplitProviderWrapperProps {
  children: ReactNode;
}

export const SplitProviderWrapper: FC<SplitProviderWrapperProps> = ({
  children,
}) => {
  const { currentUser } = useContext(AuthContext);
  const userId = !!currentUser ? currentUser.uid : "";
  const [fallbackKey, setFallbackKey] = useState<string>("");

  // this is a fallback so if no logged in user we can still use feature flags
  useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      if (!userId.length) {
        setFallbackKey("key");
      }
    }, 2000);
    return () => window.clearTimeout(timeoutID);
  }, [userId]);

  return userId.length || fallbackKey.length ? (
    <SplitProvider
      id={userId.length ? userId : fallbackKey}
      trafficType={TrafficTypes.ANONYMOUS}
    >
      <WaitForSplitClient>{children}</WaitForSplitClient>
    </SplitProvider>
  ) : (
    <LoadingSpinner />
  );
};
