/** @jsxImportSource @emotion/react */

import { AcceptTermsForm } from "./AcceptTermsForm";
import { css } from "@emotion/react";

interface UserTermsOfServiceProps {
  userId: string;
}

const styles = {
  linkText: css({
    cursor: "pointer",
    color: "#1976d2",
    textDecoration: "underline",
    fontWeight: 500,
  }),
  root: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "1rem",
  }),
  container: css({
    maxWidth: "40rem",
    margin: "1.25rem",
  }),
  header: css({
    fontSize: "1.5rem",
    fontWeight: 500,
    textAlign: "center",
  }),
  paragraph: css({
    paddingTop: "1rem",
  }),
};

export const TermsOfService = ({ userId }: UserTermsOfServiceProps) => {
  return (
    <div css={styles.root}>
      <div css={styles.container}>
        <h1 css={styles.header}>Changes to Yardzen's Terms & Privacy Policy</h1>
        <p css={styles.paragraph}>
          Please review our Terms of Service and Privacy Policy which govern
          your access to and use of our services, including our website, mobile
          applications, and any other products or services offered by Yardzen.
          By accessing the services, you agree to Yardzen's{" "}
          <a
            href="https://yardzen.com/electronic-signature-disclosures"
            target="_blank"
            rel="noreferrer"
            css={styles.linkText}
          >
            Consent to Electronic Signature and Disclosures
          </a>
          ,{" "}
          <a
            href="https://yardzen.com/terms-and-conditions"
            target="_blank"
            rel="noreferrer"
            css={styles.linkText}
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            href="https://yardzen.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
            css={styles.linkText}
          >
            Privacy Policy
          </a>
          .
        </p>
        <div css={styles.paragraph}>
          <AcceptTermsForm userId={userId} />
        </div>
      </div>
    </div>
  );
};
