import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ContractorDto } from "../api/v1/pangaeaTypes";

const initialState: FirebaseContractorState = {
  firebaseContractor: {
    id: "",
    createdAt: "",
    updatedAt: "",
    businessName: "",
    city: "",
    state: "",
    pointOfContactName: "",
    pointOfContactEmail: "",
    pointOfContactPhone: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    approved: false,
    paused: false,
    acceptingWork: false,
    pausedAt: "",
    unpausedAt: "",
    preferred: false,
    userId: "",
    initialBuildRegionId: "",
    currentBuildRegionId: "",
  },
};

export interface FirebaseContractorState {
  firebaseContractor: ContractorDto;
}

export const firebaseContractorSlice = createSlice({
  name: "firebaseContractor",
  initialState,
  reducers: {
    setFirebaseContractor: (
      state,
      action: PayloadAction<FirebaseContractorState>
    ) => {
      state.firebaseContractor = action.payload.firebaseContractor;
    },
  },
});

export const { setFirebaseContractor } = firebaseContractorSlice.actions;

export default firebaseContractorSlice.reducer;
