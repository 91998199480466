import { SegmentClient } from "./SegmentClient";

const segment = new SegmentClient();

export interface UseSegment {
  (): SegmentClient;
}

const useSegment: UseSegment = () => {
  return segment;
};

export { useSegment };
