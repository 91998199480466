// This file is autogenerated, do not edit

export interface IdentifyProps {
  [key: string]: any;
  cordial_id?: string;
  split_flags?: { [key: string]: any };
  city?: string;
  last_name?: string;
  v1_sharelink?: string;
  v2_dsat_score?: number;
  v2_sharelink?: string;
  wootric_nps_date?: string;
  v3_dsat_score?: number;
  purchased?: boolean;
  package?: string;
  wootric_nps_score?: number;
  street_address?: string;
  email?: string; // Client email address
  country?: string;
  client_requested_contractor?: boolean;
  v1_dsat_score?: number;
  phone?: string;
  yardzen_anonymous_id?: string;
  zip_code?: string;
  county?: string;
  state?: string;
  first_name?: string;
}

export interface AccountCreatedProps {
  [key: string]: any;
}

export interface AddedProps {
  [key: string]: any;
  flow_name: string;
  item_name?: string; // item removed
  sub_flow_name?: string;
  addable_name: string; // list that you are adding to
}

export interface ClickedProps {
  [key: string]: any;
  click_type: string; // e.g. button, footer, nav...
  button_name: string;
  value?: number;
  sub_flow_name?: string;
  flow_name: string;
  product_vendor?: string;
  product_name?: string;
  product_category?: string;
  product_id?: string;
  button_content?: string;
}

export interface ConversionProps {
  [key: string]: any;
  product_ids?: any;
  product_name?: string;
  amount?: number;
  product_id?: string;
  product_category?: string;
  client_reported: boolean;
  flow_name: string;
  conversion_name: string;
  product_vendor?: string;
  product_details?: string;
  sub_flow_name?: string;
}

export interface ExperimentProps {
  [key: string]: any;
  flow_name?: string;
  seen: boolean; // whether or not experiment was seen
  start_action?: string;
  sub_flow_name?: string;
  experiment: string; // name of experiment
}

export interface FlowCompletedProps {
  [key: string]: any;
  flow_name: string;
}

export interface FlowStartedProps {
  [key: string]: any;
  flow_name: string;
}

export interface FormFieldFilledProps {
  [key: string]: any;
  input_type?: string; // text, select, radio group, etc.
  option_selected?: any; // select/radio button selection
  options_selected?: any; // multiple select selection
  sub_flow_name?: string;
  field_name: string;
  flow_name?: string;
  form_name: string;
}

export interface FormSubmittedProps {
  [key: string]: any;
  passed_validation?: boolean;
  sub_flow_name?: string;
  flow_name: string;
  form_name: string;
}

export interface RemovedProps {
  [key: string]: any;
  sub_flow_name?: string;
  flow_name: string;
  item_name?: string; // item removed
  removable_name: string; // list that you are removing from
}

export interface ScrolledProps {
  [key: string]: any;
  content_name: string;
  flow_name: string;
  sub_flow_name?: string;
}

export interface SignInProps {
  [key: string]: any;
}

export interface SignOutProps {
  [key: string]: any;
}

export interface SubFlowCompletedProps {
  [key: string]: any;
  completion_action?: string;
  flow_name: string;
  sub_flow_name: string;
}

export interface SubFlowStartedProps {
  [key: string]: any;
  flow_name: string;
  start_action?: string;
  sub_flow_name: string;
}

export interface UploadedProps {
  [key: string]: any;
  sub_flow_name?: string;
  comment?: string;
  upload_time?: number; // milliseconds please
  prompt?: string;
  file_name?: string;
  file_size?: number;
  upload_name: string;
  file_type?: string;
  comment_required?: boolean;
}

export interface VideoActionProps {
  [key: string]: any;
  action: string; // play, pause...
  flow_name: string;
  sub_flow_name?: string;
  video_name: string;
}

export class SegmentClient {
  constructor() {
    if (!(window as any)?.analytics) {
      console.warn("Segment library not on window!");
    }
  }

  private track(e: string, p: any, o?: any, cb?: any): any {
    if ((window as any)?.analytics) {
      return (window as any).analytics.track(e, p, o, cb);
    }
  }

  private trackLink(
    le: HTMLElement,
    e: string,
    p: any,
    o?: any,
    cb?: any
  ): any {
    if ((window as any)?.analytics) {
      return (window as any).analytics.trackLink(le, e, p, o, cb);
    }
  }

  /**
   * Segment Track call for Account Created
   */
  public trackAccountCreated(
    properties: AccountCreatedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Account Created", properties, options, cb);
  }

  /**
   * Segment Track link call for Account Created
   */
  public trackAccountCreatedLink(
    link: HTMLElement,
    properties: AccountCreatedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Account Created", properties, options, cb);
  }

  /**
   * Segment Track call for Added
   */
  public trackAdded(
    properties: AddedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Added", properties, options, cb);
  }

  /**
   * Segment Track link call for Added
   */
  public trackAddedLink(
    link: HTMLElement,
    properties: AddedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Added", properties, options, cb);
  }

  /**
   * Segment Track call for Clicked
   */
  public trackClicked(
    properties: ClickedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Clicked", properties, options, cb);
  }

  /**
   * Segment Track link call for Clicked
   */
  public trackClickedLink(
    link: HTMLElement,
    properties: ClickedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Clicked", properties, options, cb);
  }

  /**
   * Segment Track call for Conversion
   */
  public trackConversion(
    properties: ConversionProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Conversion", properties, options, cb);
  }

  /**
   * Segment Track link call for Conversion
   */
  public trackConversionLink(
    link: HTMLElement,
    properties: ConversionProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Conversion", properties, options, cb);
  }

  /**
   * Segment Track call for Experiment
   */
  public trackExperiment(
    properties: ExperimentProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Experiment", properties, options, cb);
  }

  /**
   * Segment Track link call for Experiment
   */
  public trackExperimentLink(
    link: HTMLElement,
    properties: ExperimentProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Experiment", properties, options, cb);
  }

  /**
   * Segment Track call for Flow Completed
   */
  public trackFlowCompleted(
    properties: FlowCompletedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Flow Completed", properties, options, cb);
  }

  /**
   * Segment Track link call for Flow Completed
   */
  public trackFlowCompletedLink(
    link: HTMLElement,
    properties: FlowCompletedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Flow Completed", properties, options, cb);
  }

  /**
   * Segment Track call for Flow Started
   */
  public trackFlowStarted(
    properties: FlowStartedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Flow Started", properties, options, cb);
  }

  /**
   * Segment Track link call for Flow Started
   */
  public trackFlowStartedLink(
    link: HTMLElement,
    properties: FlowStartedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Flow Started", properties, options, cb);
  }

  /**
   * Segment Track call for Form Field Filled
   */
  public trackFormFieldFilled(
    properties: FormFieldFilledProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Form Field Filled", properties, options, cb);
  }

  /**
   * Segment Track link call for Form Field Filled
   */
  public trackFormFieldFilledLink(
    link: HTMLElement,
    properties: FormFieldFilledProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Form Field Filled", properties, options, cb);
  }

  /**
   * Segment Track call for Form Submitted
   */
  public trackFormSubmitted(
    properties: FormSubmittedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Form Submitted", properties, options, cb);
  }

  /**
   * Segment Track link call for Form Submitted
   */
  public trackFormSubmittedLink(
    link: HTMLElement,
    properties: FormSubmittedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Form Submitted", properties, options, cb);
  }

  /**
   * Segment Track call for Removed
   */
  public trackRemoved(
    properties: RemovedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Removed", properties, options, cb);
  }

  /**
   * Segment Track link call for Removed
   */
  public trackRemovedLink(
    link: HTMLElement,
    properties: RemovedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Removed", properties, options, cb);
  }

  /**
   * Segment Track call for Scrolled
   */
  public trackScrolled(
    properties: ScrolledProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Scrolled", properties, options, cb);
  }

  /**
   * Segment Track link call for Scrolled
   */
  public trackScrolledLink(
    link: HTMLElement,
    properties: ScrolledProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Scrolled", properties, options, cb);
  }

  /**
   * Segment Track call for Sign In
   */
  public trackSignIn(
    properties: SignInProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Sign In", properties, options, cb);
  }

  /**
   * Segment Track link call for Sign In
   */
  public trackSignInLink(
    link: HTMLElement,
    properties: SignInProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Sign In", properties, options, cb);
  }

  /**
   * Segment Track call for Sign Out
   */
  public trackSignOut(
    properties: SignOutProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Sign Out", properties, options, cb);
  }

  /**
   * Segment Track link call for Sign Out
   */
  public trackSignOutLink(
    link: HTMLElement,
    properties: SignOutProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Sign Out", properties, options, cb);
  }

  /**
   * Segment Track call for Sub Flow Completed
   */
  public trackSubFlowCompleted(
    properties: SubFlowCompletedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Sub Flow Completed", properties, options, cb);
  }

  /**
   * Segment Track link call for Sub Flow Completed
   */
  public trackSubFlowCompletedLink(
    link: HTMLElement,
    properties: SubFlowCompletedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Sub Flow Completed", properties, options, cb);
  }

  /**
   * Segment Track call for Sub Flow Started
   */
  public trackSubFlowStarted(
    properties: SubFlowStartedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Sub Flow Started", properties, options, cb);
  }

  /**
   * Segment Track link call for Sub Flow Started
   */
  public trackSubFlowStartedLink(
    link: HTMLElement,
    properties: SubFlowStartedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Sub Flow Started", properties, options, cb);
  }

  /**
   * Segment Track call for Uploaded
   */
  public trackUploaded(
    properties: UploadedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Uploaded", properties, options, cb);
  }

  /**
   * Segment Track link call for Uploaded
   */
  public trackUploadedLink(
    link: HTMLElement,
    properties: UploadedProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Uploaded", properties, options, cb);
  }

  /**
   * Segment Track call for Video Action
   */
  public trackVideoAction(
    properties: VideoActionProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.track("Video Action", properties, options, cb);
  }

  /**
   * Segment Track link call for Video Action
   */
  public trackVideoActionLink(
    link: HTMLElement,
    properties: VideoActionProps,
    options?: { [key: string]: any },
    cb?: any
  ): any {
    return this.trackLink(link, "Video Action", properties, options, cb);
  }

  public identify(
    userId: string,
    traits?: IdentifyProps,
    options?: { [key: string]: any }
  ): any;
  public identify(traits: IdentifyProps, options?: { [key: string]: any }): any;
  public identify(...args: any[]): any {
    if ((window as any)?.analytics) {
      return (window as any).analytics.identify(...args);
    }
  }

  private lastPage: string = "";

  public page(
    page?: string,
    properties?: {
      title?: string;
      path?: string;
      url?: string;
      referrer?: string;
    }
  ): any {
    if ((window as any)?.analytics && (!page || page !== this.lastPage)) {
      const ret = (window as any).analytics.page(page, properties);
      this.lastPage = page ?? "";
      return ret;
    }
  }

  public reset(): any {
    if ((window as any)?.analytics) {
      return (window as any).analytics.reset();
    }
  }
}
