import { getAuth } from "firebase/auth";

const logout = async () => {
  localStorage.clear();
  window.history.replaceState({}, document.title);
  const auth = getAuth();
  await auth.signOut();
};

export { logout };
