import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ErrorSlice {
  errorStatus: boolean;
  errorMessage: string;
}

const initialState: ErrorSlice = {
  errorStatus: false,
  errorMessage: "",
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ErrorSlice>) => {
      state.errorStatus = action.payload.errorStatus;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

export const { setError } = errorSlice.actions;

export default errorSlice.reducer;
