/** @jsxImportSource @emotion/react */

import Container from "@mui/material/Container";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useSegment } from "../../util/Segment/useSegment";
import { AuthContext } from "../../contexts/AuthContext";
import { useLazyGetProjectCardQuery } from "../../services/pangaeaService";
import { processProjectDataFromCard } from "../../services/projects";
import { useLogRocketIdentify } from "../../hooks/useLogRocketIdentify";
import {
  CONTACTOR_LOGIN_STORAGE,
  ContractorLoginStorage,
} from "../../services/auth";
import { setContractor } from "../../slices/contractorSlice";
import { setProjects } from "../../slices/projectSlice";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { ProjectListPage } from "../../components/ProjectListPage";
import { Button } from "@mui/material";
import { useAppSelector } from "../../hooks";
import { setError } from "../../slices/errorSlice";
import { setLoading } from "../../slices/loadingSlice";

export const ContractorPage: React.FC = () => {
  const contractorLogin = useLocalStorage<ContractorLoginStorage>(
    CONTACTOR_LOGIN_STORAGE
  )[0];
  const { loading } = useAppSelector(
    (state: { loading: any }) => state.loading
  );
  const { errorStatus, errorMessage } = useAppSelector(
    (state: { error: any }) => state.error
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const segment = useSegment();
  const { currentUser } = useContext(AuthContext);
  const [
    fetchGetProjectCard,
    { data, error: errorProjectCardProfile, isFetching: isProjectCardFetching },
  ] = useLazyGetProjectCardQuery();
  const { contractor, projects } = processProjectDataFromCard(data);
  useLogRocketIdentify({ userId: currentUser?.email || "" });

  useEffect(() => {
    if (errorProjectCardProfile)
      dispatch(
        setError({
          errorMessage: (errorProjectCardProfile as Error)?.message,
          errorStatus: true,
        })
      );
  }, [errorProjectCardProfile, dispatch]);

  useEffect(() => {
    dispatch(setLoading({ loading: isProjectCardFetching }));
  }, [isProjectCardFetching, dispatch]);

  useEffect(() => {
    if (contractor) dispatch(setContractor(contractor));
  }, [contractor, dispatch]);

  useEffect(() => {
    if (projects) {
      dispatch(setProjects({ projects }));
      dispatch(
        setError({
          errorMessage: "",
          errorStatus: false,
        })
      );
    }
  }, [projects, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchGetProjectCard({ contractorId: id });
      if (contractorLogin?.email) {
        segment.identify(contractorLogin.email, {
          conractorId: contractorLogin?.contractorId,
        });
      }
    };

    fetchData();
  }, [id, fetchGetProjectCard, contractorLogin, segment]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (errorStatus) {
    return (
      <>
        <h1>
          We're sorry, something went wrong. Please contact us at{" "}
          <a href="mailto:support@yardzen.com" type="em" className="font-bold">
            support@yardzen.com
          </a>{" "}
          for assistance
        </h1>
        <p>{errorMessage}</p>
      </>
    );
  }

  return (
    <Container>
      <Button onClick={() => navigate("/admin")}>Back to Dashboard</Button>
      {!errorProjectCardProfile && !isProjectCardFetching && projects && (
        <ProjectListPage />
      )}
    </Container>
  );
};
