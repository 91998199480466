import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface Field {
  name: string;
  value: string;
  report_value?: string;
}

export interface Project {
  id: string;
  title: string;
  current_phase_age: number;
  current_phase: {
    name: string;
  };
  fields: [Field] | [];
  child_relations: any[];
  due_date: string | null;
  address: string;
  parent_pipe?: string;
}

export interface ProjectState {
  projects: Project[];
}

const initialState: ProjectState = {
  projects: [],
};

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<ProjectState>) => {
      state.projects = action.payload.projects;
    },
  },
});

export const { setProjects } = projectSlice.actions;

export default projectSlice.reducer;
