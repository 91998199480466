/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react/macro";
import Box from "@mui/material/Box";
import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { AuthContext } from "../contexts/AuthContext";
import { TopNav } from "./TopNav";
import { useLogRocketIdentify } from "../hooks/useLogRocketIdentify";

const styles = {
  pageContainer: css({
    display: "flex",
    flexDirection: "column",
  }),
  mainContent: css({
    display: "flex",
    flexGrow: "1",
    paddingTop: "30px",
  }),
};

export const AdminLayout: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  useLogRocketIdentify({ userId: currentUser?.email || "" });

  useEffect(() => {
    if (!currentUser) navigate("/login");
  }, [currentUser, navigate]);

  return (
    <Box css={styles.pageContainer}>
      <Box>
        <TopNav loggedInEmail={currentUser?.email} isAdmin />
      </Box>
      <Box component="main" css={styles.mainContent}>
        <Outlet></Outlet>
      </Box>
    </Box>
  );
};
