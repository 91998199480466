import { Routes, Route } from "react-router-dom";
import { NoMatch } from "./components/Fallback";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { useLogRocketInit } from "./hooks/useLogRocketInit";
import { ContractorPage } from "./pages/ContractorPage";
import { ContractorPage as AdminContractorPage } from "./pages/admin/ContractorPage";
import { LoginPage } from "./pages/Login";
import { Authorize } from "./pages/Authorize";
import { DevRoute } from "./components/DevRoute";
import { ForceLogout } from "./components/ForceLogout";
import { AdminLayout } from "./components/AdminLayout";
import { DashboardPage } from "./pages/admin/DashboardPage";
import { ServiceArea } from "./pages/ServiceArea";
import { ContractorLayout } from "./components/ContractorLayout";

export const AppRouter = () => {
  useLogRocketInit();

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<LoginPage />} />
      <Route path="/authorize" element={<Authorize />} />
      <Route
        path="/force-logout"
        element={
          <DevRoute>
            <ForceLogout />
          </DevRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <ProtectedRoute adminOnly>
            <AdminLayout />
          </ProtectedRoute>
        }
      >
        <Route
          path=""
          element={
            <ProtectedRoute adminOnly>
              <DashboardPage />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="contractors/:id"
          element={
            <ProtectedRoute adminOnly>
              <AdminContractorPage />
            </ProtectedRoute>
          }
        ></Route>
      </Route>

      <Route
        path="/:id"
        element={
          <ProtectedRoute>
            <ContractorLayout />
          </ProtectedRoute>
        }
      >
        <Route
          path=""
          element={
            <ProtectedRoute adminOnly>
              <ContractorPage />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="servicearea"
          element={
            <ProtectedRoute adminOnly>
              <ServiceArea />
            </ProtectedRoute>
          }
        ></Route>
      </Route>

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};
