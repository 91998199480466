/** @jsxImportSource @emotion/react */

import { JSX } from "@emotion/react/jsx-runtime";
import { Navigate } from "react-router-dom";

export const DevRoute = ({ children }: { children: JSX.Element }) => {
  return (
    <>
      {process.env.REACT_APP_ENV === "production" ? (
        <Navigate to="/" />
      ) : (
        children
      )}
    </>
  );
};
