import { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { useLocalStorage } from "../hooks/useLocalStorage";
import {
  CONTACTOR_LOGIN_STORAGE,
  ContractorLoginStorage,
} from "../services/auth";
import { isYardzenEmail } from "../util/isYardzenEmail";

export const NoMatch = () => {
  const contractorLogin = useLocalStorage<ContractorLoginStorage>(
    CONTACTOR_LOGIN_STORAGE
  )[0];
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const adminUser = isYardzenEmail(currentUser?.email || "");

  useEffect(() => {
    if (adminUser) navigate("/admin");

    if (contractorLogin?.contractorId)
      navigate(`/${contractorLogin?.contractorId}`);
  });

  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/login">Go to the login page</Link>
      </p>
    </div>
  );
};
