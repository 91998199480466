import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuth } from "firebase/auth";
import {
  CREATE_BUILD_REGION,
  GET_BUILD_REGIONS_BY_PROJECT_LOCATION,
  GET_BUILD_REGION_BY_ID,
  UPDATE_BUILD_REGION_BY_ID,
} from "../../util/BuildRegions/graphqlQueries";
import { BuildRegion, StatusObject } from "../../types/buildRegions";
import {
  CREATE_CURRENT_USER_TERMS_OF_SERVICE,
  GET_USER_TERMS_OF_SERVICE,
} from "./graphqlQueries";

export const BANYAN_BASE_URL =
  process.env["REACT_APP_BANYAN_BASE_URL"] || "http://localhost:3000/";

type FindOneBuildRegionInput = {
  id: string;
  internalName?: string;
};

type FindAllBuildRegionInput = {
  coordinates?: [number, number];
};

type UpdateBuildRegionInput = Required<Pick<BuildRegion, "id">> &
  Partial<Omit<BuildRegion, "id">>;

type CreateBuildRegionInput = Omit<BuildRegion, "id">;

type FindAllUserTermsOfServiceInput = {
  userId: string;
  tosAgreed?: boolean;
  typeOfTos?: string;
};

type CreateCurrentUserTermsOfServiceInput = {
  dateOfAgree: any;
  tosAgreed: boolean;
  userId: string;
};

type UserTermsOfService = {
  tosAgreed: boolean;
  dateOfAgree: any;
  typeOfTos: string;
  userId: string;
};

enum BanyanApiTags {
  UserTerms = "USER_TERMS",
}

export const banyanAPI = createApi({
  reducerPath: "banyanAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: BANYAN_BASE_URL,
    prepareHeaders: async (headers) => {
      const user = getAuth()?.currentUser;
      if (user) {
        headers.set("Authorization", `Bearer ${await user.getIdToken()}`);
      }

      return headers;
    },
  }),
  tagTypes: [BanyanApiTags.UserTerms],
  endpoints: (builder) => ({
    getBuildRegionById: builder.query<BuildRegion, FindOneBuildRegionInput>({
      query: (input) => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: GET_BUILD_REGION_BY_ID,
          variables: {
            input,
          },
        },
      }),
      transformResponse: (response: any) => response.data.buildRegion,
    }),
    updateBuildRegion: builder.mutation<StatusObject, UpdateBuildRegionInput>({
      query: (input) => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: UPDATE_BUILD_REGION_BY_ID,
          variables: {
            input,
          },
        },
      }),
    }),
    createBuildRegion: builder.mutation<BuildRegion, CreateBuildRegionInput>({
      query: (input) => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: CREATE_BUILD_REGION,
          variables: {
            input,
          },
        },
      }),
      transformResponse: (response: any) => response.data.createBuildRegion,
    }),
    getBuildRegionsByProjectLocation: builder.query<
      BuildRegion[],
      FindAllBuildRegionInput
    >({
      query: (input) => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: GET_BUILD_REGIONS_BY_PROJECT_LOCATION,
          variables: {
            input,
          },
        },
      }),
    }),
    getUserTermsOfService: builder.query<
      UserTermsOfService,
      FindAllUserTermsOfServiceInput
    >({
      query: (input) => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: GET_USER_TERMS_OF_SERVICE,
          variables: {
            input,
          },
        },
      }),
      providesTags: [BanyanApiTags.UserTerms],
      transformResponse: (response: any) => response.data.findTermsOfService,
    }),
    createCurrentUserTermsOfService: builder.mutation<
      UserTermsOfService,
      CreateCurrentUserTermsOfServiceInput
    >({
      query: (input) => ({
        url: "/graphql",
        method: "POST",
        body: {
          query: CREATE_CURRENT_USER_TERMS_OF_SERVICE,
          variables: {
            input,
          },
        },
      }),
      invalidatesTags: [BanyanApiTags.UserTerms],
      transformResponse: (response: any) =>
        response.data.createCurrentUserTermsOfService,
    }),
  }),
});
