/** @jsxImportSource @emotion/react */
import { FC, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Button, CircularProgress, Container, Typography } from "@mui/material";

import { MemoizedMapWithDraw } from "./Map";
import { RegionCoordinates } from "../types/buildRegions";
import { useAppSelector } from "../hooks";
import { filterInvalidPolygons } from "../util/BuildRegions/filterInvalidPolygons";

interface BuildRegionContainerProps {
  isUpdating: boolean;
  handleUpdateContractorData: (regionCoordinates: RegionCoordinates[]) => void;
}

const styles = {
  header: css({
    borderBottom: "1px solid rgba(0,0,0,0.15)",
    marginBottom: "15px",
  }),
  buildRegionsContainer: css({
    display: "flex",
    flexDirection: "column",
    paddingTop: "30px",
  }),
  mapContainer: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  }),
  submitButton: css({
    alignSelf: "flex-end",
    marginTop: "35px",
    border: "1.8px solid",
    width: "152px",
  }),
};

const BuildRegionsContainer: FC<BuildRegionContainerProps> = ({
  isUpdating,
  handleUpdateContractorData,
}: BuildRegionContainerProps) => {
  const {
    initialBuildRegion,
    currentBuildRegion,
    currentAreaGeometry,
    defaultAreaGeometry,
  } = useAppSelector((state: any) => state.buildRegions);

  const [regionPolygonDraws, setRegionPolygonDraws] = useState<
    RegionCoordinates[]
  >([]);

  useEffect(() => {
    setRegionPolygonDraws(currentAreaGeometry);
  }, [currentAreaGeometry]);

  if (!initialBuildRegion.areaGeometry || !currentBuildRegion.areaGeometry) {
    return (
      <Container css={styles.mapContainer}>
        <CircularProgress size={40} />
      </Container>
    );
  }

  return (
    <Container css={styles.buildRegionsContainer}>
      <Typography variant="h5" component="h2" css={styles.header}>
        Zone Coverage
      </Typography>
      {initialBuildRegion.areaGeometry && currentBuildRegion.areaGeometry && (
        <MemoizedMapWithDraw
          defaultRegions={
            defaultAreaGeometry && defaultAreaGeometry.coordinates?.length
              ? defaultAreaGeometry?.coordinates
              : [[[]]]
          }
          existingRegions={
            currentAreaGeometry && currentAreaGeometry.coordinates?.length
              ? currentAreaGeometry?.coordinates
              : [[[]]]
          }
          buildRegionsBoundingBox={
            currentBuildRegion
              ? {
                  minLat: currentBuildRegion?.minLat,
                  maxLat: currentBuildRegion?.maxLat,
                  minLng: currentBuildRegion?.minLng,
                  maxLng: currentBuildRegion?.maxLng,
                }
              : initialBuildRegion
              ? {
                  minLat: initialBuildRegion?.minLat,
                  maxLat: initialBuildRegion?.maxLat,
                  minLng: initialBuildRegion?.minLng,
                  maxLng: initialBuildRegion?.maxLng,
                }
              : { minLat: 0, maxLat: 0, minLng: 0, maxLng: 0 }
          }
          onSavePolygonDraw={(coordinates) => {
            setRegionPolygonDraws(filterInvalidPolygons(coordinates));
          }}
        />
      )}
      <Button
        css={styles.submitButton}
        onClick={(e) => {
          e.preventDefault();
          handleUpdateContractorData(regionPolygonDraws);
        }}
      >
        {isUpdating ? (
          <CircularProgress size={24} />
        ) : (
          <span>Update Coverage</span>
        )}
      </Button>
    </Container>
  );
};

export { BuildRegionsContainer };
