import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface Field {
  name: string;
  value: string;
  report_value?: string;
}

export interface ClientState {
  title: string;
  fields: [Field] | [];
}

const initialState: ClientState = {
  title: "",
  fields: [],
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setClient: (state, action: PayloadAction<ClientState>) => {
      state.fields = action.payload.fields;
      state.title = action.payload.title;
    },
  },
});

export const { setClient } = clientSlice.actions;

export default clientSlice.reducer;
