import Fuse from "fuse.js";
import { Project } from "../slices/projectSlice";

interface Params {
  fullJobList: Project[];
  searchText?: string | undefined;
}

const searchOptions = {
  keys: ["title", "address"],
  threshold: 0.3,
};

export function filterJobs(params: Params): Project[] {
  const { fullJobList, searchText } = params;

  if (searchText) {
    const fuse = new Fuse(fullJobList, searchOptions);
    const results = fuse.search(searchText);
    return results.map((r) => r.item);
  }

  return fullJobList;
}
