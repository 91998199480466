import {
  getAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithRedirect,
  GoogleAuthProvider,
} from "firebase/auth";

interface AuthArgs {
  email: string;
  response: any;
}

export interface ContractorLoginStorage {
  email?: string | null;
  contractorId?: string | null;
}

export const CONTACTOR_LOGIN_STORAGE = "CONTRACTOR_LOGIN";

export const yardzenGoogleAuthLogin = async (email: string): Promise<void> => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    login_hint: email,
    hd: "yardzen.com",
  });
  await signInWithRedirect(auth, provider);
};

export const processAuthResponse = async ({ email, response }: AuthArgs) => {
  if (response) {
    const auth = getAuth();
    await sendSignInLinkToEmail(auth, email, {
      url: `${window.location.origin}/authorize?contractorId=${response}`,
      handleCodeInApp: true,
    });
    localStorage.setItem("emailForSignIn", email);
  }
};

export const validateAuth = async () => {
  const auth = getAuth();
  try {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = localStorage.getItem("emailForSignIn");
      while (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again.
        email = window.prompt("Please provide your email for confirmation");
      }

      // The client SDK will parse the code from the link for you.
      await signInWithEmailLink(auth, email, window.location.href);
      localStorage.removeItem("emailForSignIn");
    }
  } catch (err) {
    console.error("ERROR", err);
    throw err;
  }
};
