import {
  baseInProgressPhases,
  buildMarketplacePhases,
} from "../constants/phaseGroups";
import { Project } from "../slices/projectSlice";

export const processProjectDataFromCard = (data: any) => {
  if (!data) return {};

  const contractor = { title: data.title, fields: data.fields };

  let updatedProjects: any[] = [];
  if (data.parent_relations) {
    const updatedData = addParentPipeToData(data.parent_relations);
    const projects = updatedData
      .filter(
        (item: any) => item.cards.length >= 1 && item.cards[0].current_phase_age
      )
      .map((item: any) => {
        return {
          ...item,
          cards: item.cards.map((card: any) => {
            return {
              ...card,
              parent_pipe: item.parent_pipe,
            };
          }),
        };
      })
      .reduce((acc: any, curr: any) => {
        return acc.concat(curr.cards);
      }, []);

    if (projects) {
      updatedProjects = addPropertiesToProjects(projects);
    }
  }

  return { contractor, projects: updatedProjects };
};

function addParentPipeToData(parentRelations: any) {
  const [onlyClassicPhases, onlyBuildMarketplacePhases] = removeDuplicates(
    baseInProgressPhases,
    buildMarketplacePhases
  );

  return parentRelations.map((item: any) => {
    let parentPipe = "";
    for (const card of item.cards) {
      if (onlyClassicPhases.includes(card?.current_phase?.name)) {
        parentPipe = "Classic";
        break;
      } else if (
        onlyBuildMarketplacePhases.includes(card?.current_phase?.name)
      ) {
        parentPipe = "Marketplace";
        break;
      }
    }
    return {
      ...item,
      parent_pipe: parentPipe,
    };
  });
}

function removeDuplicates(array1: string[], array2: string[]) {
  const duplicates: string[] = [];

  // Create sets from arrays to easily check for duplicates
  const set1 = new Set(array1);
  const set2 = new Set(array2);

  // Iterate over one set and check for duplicates in the other set
  set1.forEach((value) => {
    if (set2.has(value)) {
      duplicates.push(value);
    }
  });

  // Filter original arrays based on unique values
  const resultArray1 = array1.filter((item) => !duplicates.includes(item));
  const resultArray2 = array2.filter((item) => !duplicates.includes(item));

  return [resultArray1, resultArray2];
}

function addPropertiesToProjects(projects: Project[]) {
  const updatedProjects = projects.map((project) => {
    return addAddressToProject(project);
  });

  return updatedProjects;
}

function addAddressToProject(project: Project) {
  const client = project?.child_relations?.find(
    (item: any) =>
      item &&
      item.cards.length >= 1 &&
      item.cards[0].fields.find((item: any) => item.name === "Full Name")
  )?.cards?.[0];
  const address = client?.fields?.find((item: any) => item.name === "Address");

  return {
    ...project,
    address: address?.value ? address.value : "Address Not Found",
  };
}
